import {
    LOGIN_USER,
    LOGIN_USER_ERROR,
    LOGIN_USER_SUCCESS,
    FETCH_DASHBOARD_DATA,
    FETCH_DASHBOARD_DATA_ERROR,
    FETCH_DASHBOARD_DATA_SUCCESS,
    FETCH_MANTRA_COUNT,
    FETCH_MANTRA_COUNT_ERROR,
    FETCH_MANTRA_COUNT_SUCCESS,
    REGISTER_USER,
    REGISTER_GUEST_USER,
    REGISTER_USER_ERROR,
    REGISTER_USER_SUCCESS,
    REGISTER_GUEST_USER_SUCCESS,
    UPDATE_MANTRA_COUNT,
    UPDATE_MANTRA_COUNT_ERORR,
    UPDATE_MANTRA_COUNT_SUCCESS,
    ADD_NEW_SESSION,
    ADD_NEW_SESSION_ERROR,
    ADD_NEW_SESSION_SUCCESS,
    RESET_NOTIFICATION,
    LOGOUT_USER,
    FETCH_CMS,
    FETCH_CMS_ERROR,
    FETCH_CMS_SUCCESS,
    EMAIL_VERIFY,
    EMAIL_VERIFY_ERROR,
    EMAIL_VERIFY_SUCCESS,
    FETCH_USER_PROFILE,
    FETCH_USER_PROFILE_ERROR,
    FETCH_USER_PROFILE_SUCCESS,
    UPDATE_USER_PROFILE,
    UPDATE_USER_PROFILE_ERROR,
    UPDATE_USER_PROFILE_SUCCESS,
    FETCH_COUNTRIES,
    FETCH_COUNTRIES_FAIL,
    FETCH_COUNTRIES_SUCCESS,
    FETCH_STATES,
    FETCH_STATES_FAIL,
    FETCH_STATES_SUCCESS,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_ERROR,
    CHANGE_PASSWORD_SUCCESS,
    RESET_PASSWORD,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_SUCCESS,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_ERROR,
    FORGOT_PASSWORD_SUCCESS,
    VERIFY_RESET_TOKEN,
    VERIFY_RESET_TOKEN_ERROR,
    VERIFY_RESET_TOKEN_SUCCESS
} from '../actionconstant';

const initialState = {
    user: (localStorage.getItem('user_id')) ? localStorage.getItem('user_id') : null,
    user_obj: (localStorage.getItem('user_obj')) ? (JSON.parse(localStorage.getItem('user_obj'))) : {},
    shownotification: false,
    dashboard: [],
    loginerror: false,
    notification_message: '',
    loading: false,
    mantra_count: [],
    session: {},
    register_user: "",
    cms: {},
    email_msg: "",
    email_status: false,
    profile: {},
    profile_msg: "",
    countries: [],
    states: [],
    verify_reset_msg: "",
    verify_reset_status : false,
    login_message : "",
    login_msg_status : false,
    register_message : "",
    registe_msg_status : false
}

const authReducer = (state = initialState, action) => {
    console.log('Action', action.type, action);

    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                loading: true
            }
        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                user: action.payload.data,
                user_obj: action.payload.data,
                token: action.payload.token,
                loginerror: false,
                shownotification: true,
                notification_message: "Login successful.",
                loading: false,
            }
        case LOGIN_USER_ERROR:
            return {
                ...state,
                loading: false,
                loginerror: true,
                shownotification: true,
                notification_message: (action.payload.message ? action.payload.message : "User Not Found")
            }
        case FETCH_DASHBOARD_DATA:
            return {
                ...state,
                loading: true
            }
        case FETCH_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                dashboard: action.payload.data,
                loginerror: false,
                shownotification: false,
                notification_message: action.payload.message,
                loading: false,
                active_devotees_across_globe: action.payload.active_devotees_across_globe,
                todaysession_totalmantra: action.payload.todaysession_totalmantra,

            }
        case FETCH_DASHBOARD_DATA_ERROR:
            return {
                ...state,
                loading: false,
                loginerror: true,
                shownotification: false,
                notification_message: action.payload.message
            }
        case REGISTER_USER:
            return {
                ...state,
                loading: true
            }
        case REGISTER_GUEST_USER:
            return {
                ...state,
                loading: true
            }
        case REGISTER_USER_SUCCESS:
            return {
                ...state,
                user: action.payload.data,
                token: action.payload.token,
                loginerror: false,
                shownotification: true,
                notification_message: "Register successful.Please check your email for your password",
                loading: false,
            }
        case REGISTER_GUEST_USER_SUCCESS:
            return {
                ...state,
                user: action.payload.data,
                token: action.payload.token,
                loginerror: false,
                shownotification: true,
                notification_message: "Register successfully as guest user.",
                loading: false,
            }
        
        case REGISTER_USER_ERROR:
            return {
                ...state,
                loading: false,
                loginerror: true,
                shownotification: true,
                notification_message: action.payload.message,
                registe_msg_status:true,
                register_message:(action.payload.newerror ? action.payload.newerror : 0)

            }

        case FETCH_MANTRA_COUNT:
            return {
                ...state,
                loading: true
            }
        case FETCH_MANTRA_COUNT_SUCCESS:
            return {
                ...state,
                mantra_count: action.payload.data,
                loading: false,
            }
        case FETCH_MANTRA_COUNT_ERROR:
            return {
                ...state,
                loading: false,
                loginerror: true,
                shownotification: true,
                notification_message: action.payload.message,
                login_message : action.payload.message,
                login_msg_status : true
            }

        case UPDATE_MANTRA_COUNT:
            return {
                ...state,
                loading: true
            }
        case UPDATE_MANTRA_COUNT_SUCCESS:
            return {
                ...state,
                mantra_count: action.payload,
                loading: false,
            }
        case UPDATE_MANTRA_COUNT_ERORR:
            return {
                ...state,
                loading: false,
                loginerror: true,
                shownotification: true,
                notification_message: action.payload.message,
                login_message : action.payload.message,
                login_msg_status : true
            }

        case ADD_NEW_SESSION:
            return {
                ...state,
                loading: true
            }
        case ADD_NEW_SESSION_SUCCESS:
            return {
                ...state,
                session: action.payload,
                loading: false,
            }
        case ADD_NEW_SESSION_ERROR:
            return {
                ...state,
                loading: false,
                loginerror: true,
                shownotification: true,
                notification_message: action.payload.message
            }
        case RESET_NOTIFICATION:
            return {
                ...state,
                loading: false,
                shownotification: false,
                notification_message: "",
                loginerror: false,
            }
        case LOGOUT_USER:
            return {
                ...state,
                loading: true
            }
        case FETCH_CMS:
            return {
                ...state,
                loading: true
            }
        case FETCH_CMS_SUCCESS:
            return {
                ...state,
                cms: action.payload.data,
                loading: false,
            }
        case FETCH_CMS_ERROR:
            return {
                ...state,
                loading: false,
                loginerror: true,
                shownotification: true,
                notification_message: action.payload.message
            }
        case EMAIL_VERIFY:
            return {
                ...state,
                loading: true
            }
        case EMAIL_VERIFY_SUCCESS:
            return {
                ...state,
                email_status: false,
                email_msg: action.payload.message,
                loading: false,
            }
        case EMAIL_VERIFY_ERROR:
            return {
                ...state,
                loading: false,
                email_status: true,
                email_msg: action.payload.message
            }
        case FETCH_USER_PROFILE:
            return {
                ...state,
                loading: true
            }
        case FETCH_USER_PROFILE_SUCCESS:
            return {
                ...state,
                user_obj: action.payload.data,
                loading: false,
            }
        case FETCH_USER_PROFILE_ERROR:
            return {
                ...state,
                loading: false,
                loginerror: true,
                shownotification: false,
                notification_message: action.payload.message,
                login_message : action.payload.message,
                login_msg_status : true
            }



        case UPDATE_USER_PROFILE:
            return {
                ...state,
                loading: true
            }
        case UPDATE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                loginerror: false,
                shownotification: true,
                notification_message: action.payload.message
            }
        case UPDATE_USER_PROFILE_ERROR:
            return {
                ...state,
                loading: false,
                loginerror: true,
                shownotification: true,
                notification_message: action.payload.message,
                login_message : action.payload.message,
                login_msg_status : true
            }
        case FETCH_COUNTRIES_SUCCESS:
            return {
                ...state,
                countries: action.payload.data
            }
        case FETCH_STATES_SUCCESS:
            return {
                ...state,
                states: action.payload.data
            }
        case CHANGE_PASSWORD:
            return {
                ...state,
                loading: true
            }
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loginerror: false,
                notification_message: action.payload.message,
                loading: false,
            }
        case CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                loginerror: true,
                notification_message: action.payload.message,
                login_message : action.payload.message,
                login_msg_status : true
            }

            case FORGOT_PASSWORD:
            return {
                ...state,
                loading: true
            }
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loginerror: false,
                notification_message: action.payload.message,
                loading: false,
                verify_reset_status : true
            }
        case FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                loginerror: true,
                verify_reset_status : true,
                notification_message: action.payload.message
            }

            case VERIFY_RESET_TOKEN:
            return {
                ...state,
                loading: true
            }
        case VERIFY_RESET_TOKEN_SUCCESS:
            return {
                ...state,
                verify_reset_status: true,
                verify_reset_msg: action.payload.message,
                loading: false,
            }
        case VERIFY_RESET_TOKEN_ERROR:
            return {
                ...state,
                loading: false,
                verify_reset_status:false,
                verify_reset_msg: action.payload.message
            }

            case RESET_PASSWORD:
            return {
                ...state,
                loading: true
            }
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loginerror: false,
                notification_message: action.payload.message,
                loading: false,
            }
        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                loginerror: true,
                notification_message: action.payload.message
            }

        default:
            return state
    }
}
export default authReducer;