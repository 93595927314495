export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const FETCH_DASHBOARD_DATA = "FETCH_DASHBOARD_DATA";
export const FETCH_DASHBOARD_DATA_SUCCESS = "FETCH_DASHBOARD_DATA_SUCCESS";
export const FETCH_DASHBOARD_DATA_ERROR = "FETCH_DASHBOARD_DATA_ERROR";

export const FETCH_MANTRA_COUNT = "FETCH_MANTRA_COUNT";
export const FETCH_MANTRA_COUNT_SUCCESS = "FETCH_MANTRA_COUNT_SUCCESS";
export const FETCH_MANTRA_COUNT_ERROR = "FETCH_MANTRA_COUNT_ERROR";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_GUEST_USER = "REGISTER_GUEST_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_GUEST_USER_SUCCESS = "REGISTER_GUEST_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";

export const UPDATE_MANTRA_COUNT = "UPDATE_MANTRA_COUNT";
export const UPDATE_MANTRA_COUNT_SUCCESS = "UPDATE_MANTRA_COUNT_SUCCESS";
export const UPDATE_MANTRA_COUNT_ERORR = "UPDATE_MANTRA_COUNT_ERROR";

export const ADD_NEW_SESSION = "ADD_NEW_SESSION";
export const ADD_NEW_SESSION_SUCCESS = "ADD_NEW_SESSION_SUCCESS";
export const ADD_NEW_SESSION_ERROR = "ADD_NEW_SESSION_ERROR";

export const RESET_NOTIFICATION = "RESET_NOTIFICATION"; 
export const LOGOUT_USER = "LOGOUT_USER";

export const FETCH_CMS = "FETCH_CMS";
export const FETCH_CMS_SUCCESS = "FETCH_CMS_SUCCESS";
export const FETCH_CMS_ERROR = "FETCH_CMS_ERROR"; 

export const EMAIL_VERIFY = "EMAIL_VERIFY";
export const EMAIL_VERIFY_SUCCESS = "EMAIL_VERIFY_SUCCESS";
export const EMAIL_VERIFY_ERROR = "EMAIL_VERIFY_ERROR";

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_ERROR = "UPDATE_USER_PROFILE_ERROR";

export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";
export const FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS";
export const FETCH_USER_PROFILE_ERROR = "FETCH_USER_PROFILE_ERROR";

export const FETCH_COUNTRIES = "FETCH_COUNTRIES"
export const FETCH_COUNTRIES_SUCCESS = "FETCH_COUNTRIES_SUCCESS"
export const FETCH_COUNTRIES_FAIL = "FETCH_COUNTRIES_FAIL"

export const FETCH_STATES = "FETCH_STATES"
export const FETCH_STATES_SUCCESS = "FETCH_STATES_SUCCESS"
export const FETCH_STATES_FAIL = "FETCH_STATES_FAIL"

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const LIST_ACTIVEDEVOTEE_STATISTICS = "LIST_ACTIVEDEVOTEE_STATISTICS"
export const LIST_ACTIVEDEVOTEE_STATISTICS_SUCCESS = "LIST_ACTIVEDEVOTEE_STATISTICS_SUCCESS"
export const LIST_ACTIVEDEVOTEE_STATISTICS_FAIL = "LIST_ACTIVEDEVOTEE_STATISTICS_FAIL"

export const RESET_ACTIVEDEVOTEE_NOTIFICATION = "RESET_ACTIVEDEVOTEE_NOTIFICATION"

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const VERIFY_RESET_TOKEN = "VERIFY_RESET_TOKEN";
export const VERIFY_RESET_TOKEN_ERROR = "VERIFY_RESET_TOKEN_ERROR";
export const VERIFY_RESET_TOKEN_SUCCESS = "VERIFY_RESET_TOKEN_SUCCESS";
