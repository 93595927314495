import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
// changesv1
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import ListItemText from "@material-ui/core/ListItemText";
// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
import AddAlert from "@material-ui/icons/AddAlert";
// import LockOutline from "@material-ui/icons/LockOutline";
import Link from '@material-ui/core/Link';

// core components
import Snackbar from "components/Snackbar/SnackbarContent.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js"
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { loginUser,registerGuestUser, resetNotification } from '../../redux/action'

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";
import { Component } from "react";



class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.setCardAnimation = this.setCardAnimation.bind(this);
    this.state = {
      email: "",
      emailerrorText : "",
      passworderrorText :"",
      emailState: "",
      password: "",
      passwordState: "",
      cardAnimaton: "cardHidden",
      notification: false
    }
  }

  componentDidMount() {
    let ths = this;
    setTimeout(function () {
      ths.setCardAnimation("");
    }, 700);
  }
  setNotify(val = true) {
    console.log('in set notification', val);

    this.setState({ notification: val })
    let ths = this;
    setTimeout(() => {
      ths.setState({ notification: false })
    }, 2000)
    // this.props.resetNotification();
  }

  verifyLength(val, length) {
    if (val.length >= length) {
      return true;
    }
    return false;
  };
  verifyEmail(val) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(val.trim())) {
      return true;
    } else{
      this.setState({
        emailerrorText : "Please Enter Valid Email"
      });
      return false;
    }
  }
  setpasswordState(val = "") {
    if(val==="error"){
      this.setState({
        passworderrorText : "Please Enter Password"
      })
    }
    this.setState({ passwordState: val })
  }
  setemailState(val = "") {
    if(val==="error"){
      this.setState({
        emailerrorText : "Please Enter Valid Email"
      })
    }
    
    this.setState({ emailState: val })
  }
  setCardAnimation(val = "") {
    this.setState({ cardAnimaton: val })
  }
  setemail(val = "") {
    this.setState({ email: val.trim() })
  }
  setpassword(val = "") {
    this.setState({ password: val })
  }
  onSubmit(e,val = {}) {
    e.preventDefault();
    console.log('Test', val, this.props);
    // this.setNotify() ;
    if (this.state.emailState === "") {
      this.setemailState("error");
    }
    if (this.state.passwordState === "") {
      this.setpasswordState("error");
    }
    if (val.email && val.password) {
      console.log("login called");
      this.props.loginUser(val, this.props.history);
    }
  }

    onGuestSubmit = (e, val = {}) => {
    console.log("here ==>");
    e.preventDefault();
    // if (this.state.emailState === "") {
    //   this.setemailState("error");
    // }
    // var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    // if (val.email) {
      
    //   if (emailRex.test(val.email.trim())) {
        this.props.registerGuestUser(val, this.props.history);
    //   }else{
    //     this.setemailState("error");
    //   }
    // }
  }
  render() {
    const { email, password, cardAnimaton, emailState, passwordState,emailerrorText,passworderrorText } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <div
          className={classes.pageHeader}
        // style={{
        //   backgroundImage: "url(" + image + ")",
        //   backgroundSize: "cover",
        //   backgroundPosition: "top center"
        // }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <form className={classes.form} onSubmit={(e) => this.onSubmit(e,{ email, password })}>

                    <CardHeader>
                      <h2 className={`${classes.textCenter} ${classes.blockTitle}`}>Login</h2>
                    </CardHeader>
                    <CardBody signup>
                      <CustomInput
                        success={emailState === "success"}
                        error={emailState === "error"}
                        labelText="Email..."
                        id="email"
                        errorlabelText = {emailerrorText}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{

                          onChange: ((e) => {
                            if (this.verifyEmail(e.target.value)) {
                              this.setemailState("success");
                            } else {
                              this.setemailState("error");
                            }
                            this.setemail(e.target.value)
                          })
                        }}
                      />
                      <CustomInput
                        success={passwordState === "success"}
                        error={passwordState === "error"}
                        labelText="Password"
                        id="password"
                        errorlabelText = {passworderrorText}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{

                          type: "password",
                          autoComplete: "off",
                          onChange: ((e) => {
                            if (this.verifyLength(e.target.value, 1)) {
                              this.setpasswordState("success");
                            } else {
                              this.setpasswordState("error");
                            }
                            this.setpassword(e.target.value)
                          })
                        }}
                      />
                    </CardBody>
                    <div className={`${classes.textCenter} ${classes.logSignFoot}`}>

                      <Button type="submit" color="success" size="lg">
                        Login
                    </Button>
                      <p style={{ paddingTop: "20px" }}><Link href="/forgot-password" style={{ color: "#4caf50" }}><b>Forgot Password</b></Link></p>
                      <p style={{ paddingTop: "20px" }}>Don't have an account? <Link href="/signup" style={{ color: "#4caf50" }}><b>Signup</b></Link></p>
                      <Link href="/login" onClick={(e) => this.onGuestSubmit(e)} style={{color : "#4caf50", fontSize: "19px"}}><b>Login as Guest User</b></Link>
                    </div>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
            {
              this.props.loginerror &&
              <Snackbar
                place="tr"
                color={(this.props.loginerror) ? "danger" : "info"}
                icon={AddAlert}
                message={`${this.props.notification_message}`}
                open={this.props.shownotification}
                closeNotification={() => {
                  this.setNotify(false);
                }}
                close
              />
            }
            {
              this.props.login_msg_status && this.props.login_message && this.props.login_message !="Invalid current password." &&
              <Snackbar
                place="tr"
                color={"danger"}
                icon={AddAlert}
                message={`${this.props.login_message}`}
                open={this.props.shownotification}
                closeNotification={() => {
                  this.setNotify(false);
                }}
                close
              />
            }
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object
};
const mapStateToProps = state => {
  console.log('in maptoprops: in login page', state);
  return {
    user: state.auth.user,
    shownotification: state.auth.shownotification,
    loginerror: state.auth.loginerror,
    notification_message: state.auth.notification_message,
    login_message : state.auth.login_message,
    login_msg_status : state.auth.login_msg_status
  };
};

const mapDispatchToProps = { loginUser,registerGuestUser, resetNotification };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPageStyle)(LoginPage));