/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import Link from '@material-ui/core/Link';

import styles from "assets/jss/material-kit-pro-react/components/footerStyle.js";
import Container from "@material-ui/core/Container";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const { children, content, theme, big, className , ishomepage } = props;
  const classes = useStyles();
  const themeType =
    theme === "transparent" || theme == undefined ? false : true;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes[theme]]: themeType,
    [classes.big]: big || children !== undefined,
    [className]: className !== undefined
  });
  const aClasses = classNames({
    [classes.a]: true
  });

  return (
    <Container fixed className='generalFooter'>
      <Grid container className='left-centerInResponsive'>
        <Grid item lg={(ishomepage)?2:4} md={12} xs={12}>
          &copy; {new Date().getFullYear()} UinUniverse.
                        </Grid>
        {(ishomepage) && <Grid item lg={3} md={12} xs={12}>
              Audio credit: Palak & Ashish Vyas          
        </Grid>}
        <Grid item lg={(ishomepage)?3:4} md={12} xs={12} className={classes.textCenter}>
          <div className={classes.footer}>
            <Link href="/user-agreement" className={classes.footerLinks}>User Agreement</Link>
            <Link href="/privacy-policy" className={classes.footerLinks}>Privacy</Link>
            <Link href="/about-us" className={classes.footerLinks}>About</Link>
          </div>
        </Grid>
        <Grid item lg={4} md={12} xs={12} className='right-centerInResponsive'>
          <p>Created by <Link href="https://www.intuz.com/" target="_blank" style={{color : "#000"}}><b>Intuz</b></Link> in association with <b>UinUniverse</b>. </p>
        </Grid>
      </Grid>
    </Container>
  );
}

Footer.propTypes = {
  theme: PropTypes.oneOf(["dark", "white", "transparent"]),
  big: PropTypes.bool,
  // content: PropTypes.node.isRequired
};
