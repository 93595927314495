import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
// changesv1
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import ListItemText from "@material-ui/core/ListItemText";
// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
import AddAlert from "@material-ui/icons/AddAlert";
// import LockOutline from "@material-ui/icons/LockOutline";
import Link from '@material-ui/core/Link';

// core components
import Snackbar from "components/Snackbar/SnackbarContent.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js"
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { verifyResetToken, resetPassword, resetNotification } from '../../redux/action'

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";
import { Component } from "react";


class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.setCardAnimation = this.setCardAnimation.bind(this);
        this.state = {
            newpassword: "",
            newpasswordState: "",
            cardAnimaton: "cardHidden",
            notification: false,
            token: "",
            confirmpassword: "",
            confirmpasswordState: "",
            toast_message: "",
            newpwderrorText: "",
            confirmpwderrorText: ""

        }
    }
    componentDidMount() {
        let ths = this;
        setTimeout(function () {
            ths.setCardAnimation("");
        }, 700);


        if (this.props.match && this.props.match.params && this.props.match.params.id) {
            this.setState({
                token: this.props.match.params.id
            }, () => {
                this.props.verifyResetToken({ token: this.state.token });
            })
        }

    }
    setNotify(val = true) {
        console.log('in set notification', val);

        this.setState({ notification: val })
        let ths = this;
        setTimeout(() => {
            ths.setState({ notification: false })
        }, 2000)
        // this.props.resetNotification();
    }

    verifyLength(val, length) {
        if (val.length >= length) {
            return true;
        }
        return false;
    };
    verifyEmail(val) {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(val)) {
            return true;
        }
        return false;
    }
    setemailState(val = "") {
        this.setState({ emailState: val })
    }
    setCardAnimation(val = "") {
        this.setState({ cardAnimaton: val })
    }
    setemail(val = "") {
        this.setState({ email: val })
    }
    onSubmit(e, val = {}) {
        console.log('Test', val, this.props);
        // this.setNotify() ;
        e.preventDefault();
        if (this.state.newpasswordState === "") {
            this.setnewpasswordState("error");
        }
        if (this.state.confirmpasswordState === "") {
            this.setconfirmpasswordState("error");
        }
        if (val.newpassword && val.confirmpassword && val.newpassword === val.confirmpassword) {
            var data = {
                confirmpassword: val.newpassword,
                token: this.state.token
            }
            this.props.resetPassword(data, this.props.history);
        } else {
            if (val.newpassword !== val.confirmpassword) {
                console.log("=====================================")
                this.setconfirmpasswordState("error");
            }
        }
    }
    setnewpasswordState(val = "") {
        if (val === "error") {
            this.setState({
                newpwderrorText: "Please Enter Password"
            })
        }
        this.setState({ newpasswordState: val })
    }
    setconfirmpasswordState(val = "") {
        if (val === "error") {
            this.setState({
                confirmpwderrorText: (this.state.confirmpassword === "" ? "Please Enter Confirm Password" : "Confirm Password doesn't match") 
            })
        }
        this.setState({ confirmpasswordState: val })
    }
    setnewpassword(val = "") {
        this.setState({ newpassword: val })
    }
    setconfirmpassword(val = "") {
        this.setState({ confirmpassword: val })
    }
    verifyConfirmpassword(val, lenght = 1) {
        if (val.length >= lenght) {
            if (val === this.state.newpassword) {
                return true;
            } else {
                this.setState({
                    confirmpwderrorText: "Confirm Password doesn't match"
                })
                return false;
            }
        }
        return false;
    }
    render() {
        const { confirmpassword, newpassword, cardAnimaton, confirmpasswordState, newpasswordState, newpwderrorText, confirmpwderrorText } = this.state;
        const { classes } = this.props;
        return (
            <div>
                <div
                    className={classes.pageHeader}
                >
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={6}>
                                <Card>
                                    <form className={classes.form} onSubmit={(e) => this.onSubmit(e, { newpassword, confirmpassword })}>

                                        <CardHeader>
                                            <h2 className={`${classes.textCenter} ${classes.blockTitle}`}>Reset Password</h2>
                                        </CardHeader>
                                        <CardBody signup>
                                            <CustomInput
                                                errorlabelText={newpwderrorText}
                                                success={newpasswordState === "success"}
                                                error={newpasswordState === "error"}
                                                labelText="New Password"
                                                id="password"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{

                                                    type: "password",
                                                    autoComplete: "off",
                                                    onChange: ((e) => {
                                                        if (this.verifyLength(e.target.value, 1)) {
                                                            this.setnewpasswordState("success");
                                                        } else {
                                                            this.setnewpasswordState("error");
                                                        }
                                                        this.setnewpassword(e.target.value)
                                                    })
                                                }}
                                            />
                                            <CustomInput
                                                errorlabelText={confirmpwderrorText}
                                                success={confirmpasswordState === "success"}
                                                error={confirmpasswordState === "error"}
                                                labelText="Confirm Password"
                                                id="confirmpassword"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{

                                                    type: "password",
                                                    autoComplete: "off",
                                                    onChange: ((e) => {
                                                        if (this.verifyConfirmpassword(e.target.value, 1)) {
                                                            this.setconfirmpasswordState("success");
                                                        } else {
                                                            this.setconfirmpasswordState("error");
                                                        }
                                                        this.setconfirmpassword(e.target.value)
                                                    })
                                                }}
                                            />
                                        </CardBody>
                                        <div className={`${classes.textCenter} ${classes.logSignFoot}`}>

                                            <Button color="success" size="lg" type="submit" disabled={!this.props.verify_reset_status && this.props.verify_reset_msg ? true : false}>
                                                Reset Password
                        </Button>
                                            <p style={{ paddingTop: "20px" }}>Back To Login <Link href="/login" style={{ color: "#4caf50" }}>Login</Link></p>
                                        </div>
                                    </form>
                                </Card>
                            </GridItem>
                        </GridContainer>
                        {
                            this.props.notification_message &&
                            <Snackbar
                                place="tr"
                                color={(this.props.loginerror) ? "danger" : "success"}
                                icon={AddAlert}
                                message={`${this.props.notification_message}`}
                                open={this.props.shownotification}
                                closeNotification={() => {
                                    this.setNotify(false);
                                }}
                                close
                            />
                        }
                        {
                            !this.props.verify_reset_status && this.props.verify_reset_msg &&
                            <Snackbar
                                place="tr"
                                color={(!this.props.verify_reset_status) ? "danger" : "success"}
                                icon={AddAlert}
                                message={`${this.props.verify_reset_msg}`}
                                open={this.props.shownotification}
                                closeNotification={() => {
                                    this.setNotify(false);
                                }}
                                close
                            />
                        }
                        {
                            this.state.toast_message !== "" &&
                            <Snackbar
                                place="tr"
                                color={"danger"}
                                icon={AddAlert}
                                message={`${this.state.toast_message}`}
                                open={true}
                                closeNotification={() => {

                                }}
                                close
                            />
                        }
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
};

ForgotPassword.propTypes = {
    classes: PropTypes.object
};
const mapStateToProps = state => {
    console.log('in maptoprops: in reset====> page', state);
    return {
        user: state.auth.user,
        shownotification: state.auth.shownotification,
        loginerror: state.auth.loginerror,
        notification_message: state.auth.notification_message,
        verify_reset_status: state.auth.verify_reset_status,
        verify_reset_msg: state.auth.verify_reset_msg
    };
};

const mapDispatchToProps = { verifyResetToken, resetPassword, resetNotification };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPageStyle)(ForgotPassword));