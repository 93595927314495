import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.8.0";

// pages for this product
import LoginPage from "views/LoginPage/LoginPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js"
import ActiveDevoteeStatistics from "views/ActiveDevoteeStatistics/ActiveDevoteeStatistics.js";
import SignupPage from "views/SignupPage/SignupPage.js";
import ErrorPage from "views/ErrorPage/ErrorPage.js";
import DashboardPage from "views/DashboardPage/DashboardPage.js";
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import AboutUs from "views/AboutUsPage/AboutUsPage.js"
import PrivacyPolicy from "views/PrivacyPolicy/PrivacyPolicy.js";
import UserAgreement from "views/UserAgreement/UserAgreement.js";
import EmailVerify from "views/EmailVerify/EmailVerify.js";
import ChangePassword from "views/ChangePassword/ChangePassword.js";
import ForgotPassword from "views/ForgorPassword/ForgotPassword.js";
import ResetPassword from "views/ResetPassword/ResetPassword.js"

var hist = createBrowserHistory();

const ProtectedRoute = ({component: Component, authed, ...rest}) => {
    return (
      <Route 
        {...rest}
        render={(props) => (localStorage.getItem('token'))?
          <Component {...props}/>
          :
          <Redirect
            to={{
              pathname : "/login",
              state : { from : props.location }
            }}
          />
          }
      />
    )
}


ReactDOM.render(
  <Provider store={configureStore()}>
  <Router history={hist}>
    <Switch>
      <Route exact path="/error-page" component={ErrorPage} />
      <Route exact path="/" component={DashboardPage} />
      <Route exact path="/login" component={LoginPage} />
      <ProtectedRoute exact path="/edit-profile" component={ProfilePage} />
      <ProtectedRoute exact path="/statistics" component={ActiveDevoteeStatistics}/>
      <Route exact path="/dashboard" component={DashboardPage} />
      <Route exact path="/signup" component={SignupPage} />
      <Route exact path="/about-us" component={AboutUs} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/user-agreement" component={UserAgreement} />
      <Route exact path="/emailverify/:id" component={EmailVerify} />
      <Route exact path="/change-password" component={ChangePassword} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/auth/reset-password/:id" component={ResetPassword} />
    </Switch>
  </Router>
  </Provider>,
  document.getElementById("root")
);
