import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
// changesv1
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import ListItemText from "@material-ui/core/ListItemText";
// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
import AddAlert from "@material-ui/icons/AddAlert";
// import LockOutline from "@material-ui/icons/LockOutline";
import Link from '@material-ui/core/Link';

// core components
import Snackbar from "components/Snackbar/SnackbarContent.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js"
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { forgotPassword, resetNotification } from '../../redux/action'

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";
import { Component } from "react";


class ForgotPassword extends Component {
    constructor(props){
        super(props);
        this.setCardAnimation = this.setCardAnimation.bind(this);
        this.state = {
          email: "",
          emailState: "",
          cardAnimaton: "cardHidden",
          notification: false,
          emailerrorText : "",
        }
    }
    componentDidMount() {
        let ths = this;
        setTimeout(function () {
          ths.setCardAnimation("");
        }, 700);
      }
      setNotify(val = true) {
        console.log('in set notification', val);
    
        this.setState({ notification: val })
        let ths = this;
        setTimeout(() => {
          ths.setState({ notification: false })
        }, 2000)
        // this.props.resetNotification();
      }
    
      verifyLength(val, length) {
        if (val.length >= length) {
          return true;
        }
        return false;
      };
      verifyEmail(val) {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(val.trim())) {
          return true;
        } else{
          this.setState({
            emailerrorText : "Please Enter Valid Email"
          });
          return false;
        }
      }
      setemailState(val = "") {
        if(val==="error"){
          this.setState({
            emailerrorText : "Please Enter Valid Email"
          })
        }
        this.setState({ emailState: val })
      }
      setCardAnimation(val = "") {
        this.setState({ cardAnimaton: val })
      }
      setemail(val = "") {
        this.setState({ email: val.trim() })
      }
      onSubmit(e,val = {}) {
        console.log('Test', val, this.props);
        e.preventDefault();
        // this.setNotify() ;
        if (this.state.emailState === "") {
          this.setemailState("error");
        }
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
        if (val.email) {
          if (emailRex.test(val.email.trim())) {
          this.props.forgotPassword(val, this.props.history);
          }else{
            this.setemailState("error");
          }
        }
      }
      render(){
        const { email, cardAnimaton, emailState,emailerrorText} = this.state;
        const { classes } = this.props;
          return(
            <div>
            <div
              className={classes.pageHeader}
            >
              <div className={classes.container}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={6}>
                    <Card>
                      <form className={classes.form} onSubmit={(e) => this.onSubmit(e,{ email })}>
    
                        <CardHeader>
                          <h2 className={`${classes.textCenter} ${classes.blockTitle}`}>Forgot Password</h2>
                        </CardHeader>
                        <CardBody signup>
                          <CustomInput
                            success={emailState === "success"}
                            error={emailState === "error"}
                            labelText="Email..."
                            errorlabelText = {emailerrorText}
                            id="email"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
    
                              onChange: ((e) => {
                                if (this.verifyEmail(e.target.value)) {
                                  this.setemailState("success");
                                } else {
                                  this.setemailState("error");
                                }
                                this.setemail(e.target.value)
                              })
                            }}
                          />
                        </CardBody>
                        <div className={`${classes.textCenter} ${classes.logSignFoot}`}>
    
                          <Button color="success" size="lg" type="submit">
                            Forgot Password
                        </Button>
                          <p style={{ paddingTop: "20px" }}>Back To <Link href="/login" style={{color : "#4caf50"}}><b>Login</b></Link></p>
                        </div>
                      </form>
                    </Card>
                  </GridItem>
                </GridContainer>
                {
                  this.props.notification_message &&
                  <Snackbar
                    place="tr"
                    color={(this.props.loginerror) ? "danger" : "success"}
                    icon={AddAlert}
                    message={`${this.props.notification_message}`}
                    open={this.props.shownotification}
                    closeNotification={() => {
                      this.setNotify(false);
                    }}
                    close
                  />
                }
              </div>
            </div>
            <Footer/>
          </div>
          );
      }
};

ForgotPassword.propTypes = {
    classes: PropTypes.object
  };
  const mapStateToProps = state => {
    console.log('in maptoprops: in login page', state);
    return {
      user: state.auth.user,
      shownotification: state.auth.shownotification,
      loginerror: state.auth.loginerror,
      notification_message: state.auth.notification_message
    };
  };
  
  const mapDispatchToProps = { forgotPassword, resetNotification };
  
  export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPageStyle)(ForgotPassword));