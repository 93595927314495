import {
  container,
  title,
  cardTitle,
  main,
  mainRaised,
  mrAuto,
  whiteColor,
  grayColor,
  mlAuto,
  blackColor,
  cardHeader,
  dangerColor,
} from "assets/jss/material-kit-pro-react.js";

import buttonGroup from "assets/jss/material-kit-pro-react/buttonGroupStyle.js";
import tooltips from "assets/jss/material-kit-pro-react/tooltipsStyle.js";

const styles = theme => ({
  main,
  mainRaised,
  mrAuto,
  mlAuto,
  cardTitle,
  ...buttonGroup,
  ...tooltips,
  container: {
    ...container,
    zIndex: 1
  },
  title: {
    ...title,
    "&, & + h4": {
      color: whiteColor
    }
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  },
  imgContainer: {
    width: "120px",
    maxHeight: "160px",
    overflow: "hidden",
    display: "block",
    "& img": {
      width: "100%"
    }
  },
  description: {
    maxWidth: "150px"
  },
  tdName: {
    minWidth: "200px",
    fontWeight: "400",
    fontSize: "1.5em"
  },
  tdNameAnchor: {
    color: grayColor[1]
  },
  tdNameSmall: {
    color: grayColor[0],
    fontSize: "0.75em",
    fontWeight: "300"
  },
  tdNumber: {
    textAlign: "right",
    minWidth: "150px",
    fontWeight: "300",
    fontSize: "1.125em !important"
  },
  tdNumberSmall: {
    marginRight: "3px"
  },
  tdNumberAndButtonGroup: {
    lineHeight: "1 !important",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0"
    }
  },
  customFont: {
    fontSize: "16px !important"
  },
  actionButton: {
    margin: "0px",
    padding: "5px"
  },
  textCenter: {
    textAlign: "center"
  },
  textRight: {
    textAlign: "right"
  },
  grayColor: {
    color: grayColor[0]
  },
  blackColor: {
    color: blackColor
  },
  topTitle: {
    marginTop: '0',
    paddingTop: "10px",
    
  },
  devoteSub: {
    fontSize: "1rem",
    color: blackColor,
    textAlign: "center",
    display: "block",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  footer: {
    // display: "block",
    // padding: "15px 0",
    // width: "100%",
    // textAlign: "center",
    // [theme.breakpoints.down("md")]: {
    //   position: "static",
    // },
    [theme.breakpoints.down("md")]: {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
  },
  footerLinks: {
    fontSize: "16px",
    color: grayColor[3],
    margin: "0 15px",
    '&:hover': {
      color: grayColor[1],
    }
  },
  statsdata: {
    width:"100%",
    display:"contents"
  },
  statename:{
    padding:"2px 0px 0px 20px"
  },
  todaybottom:{
    padding:"2px 0px 10px 0px"
  },
  countrydata:{
    padding:"5px 0px 0px 0px"
  },
  statcardmargin:{
    margin:"0px 0px 20px 20px"
  }, 
  statsbutton:{
    marginLeft : "20px"
  },
  h3Style: {
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4rem",
      margin: "0"
      // paddingBottom: "10px",
    },
  },
  labelRootError: {
    color: dangerColor[0] + " !important"
  },
  textSize:{
    fontSize : "1rem !important"
  }
});

export default styles;
