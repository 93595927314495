/*eslint-disable*/
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import Link from '@material-ui/core/Link';
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import parser from 'react-html-parser';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
// sections for this page

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import { resetNotification, getCms, logoutUser } from '../../redux/action'



class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.setCardAnimation = this.setCardAnimation.bind(this);
    this.state = {
      cardAnimaton: "cardHidden",
      notification: false,
      is_token: 0
    }
  }
  setCardAnimation(val = "") {
    this.setState({ cardAnimaton: val })
  }

  componentDidMount() {
    let ths = this;
    setTimeout(function () {
      ths.setCardAnimation("");
    }, 700);
    this.props.getCms({ type: "agreement" })
    var token = localStorage.getItem('token');
    if (token) {
      this.setState({
        is_token: 1
      })
    }

  }
  setNotify(val = true) {
    console.log('in set notification', val);

    this.setState({ notification: val })
    let ths = this;
    setTimeout(() => {
      ths.setState({ notification: false })
    }, 2000)
    // this.props.resetNotification();
  }
  handleLogout = () => {
    this.props.logoutUser(this.props.history);
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header
          handleLogout={() => this.handleLogout()}
          history={this.props.history}
        />
        <Parallax>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem
                md={8}
                sm={8}
                className={classNames(
                  classes.mlAuto,
                  classes.mrAuto,
                  classes.textCenter
                )}
              >
                {parser(this.props.cms.content)}
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <Footer/>
        {/* <Container fixed className='generalFooter'>
          <Grid container className='left-centerInResponsive'>
            <Grid item lg={4} md={12} xs={12}>
              Copyrights are reserved.
          </Grid>
            <Grid item lg={4} md={12} xs={12} className={classes.textCenter}>
              <div className={classes.footer}>
                <Link href="/user-agreement" className={classes.footerLinks}>User Agreement</Link>
                <Link href="/privacy-policy" className={classes.footerLinks}>Privacy</Link>
                <Link href="/about-us" className={classes.footerLinks}>About</Link>
              </div>
            </Grid>
            <Grid item lg={4} md={12} xs={12} className='right-centerInResponsive'>
              <p>Created by <b>Intuz</b> in association with <b>UinUniverse</b>. </p>
            </Grid>
          </Grid>
        </Container> */}
      </div>
    );
  }
}

DashboardPage.propTypes = {
  classes: PropTypes.object
};
const mapStateToProps = state => {
  console.log('in maptoprops:======>', state.auth);
  return {
    shownotification: state.auth.shownotification,
    loginerror: state.auth.loginerror,
    notification_message: state.auth.notification_message,
    cms: state.auth.cms
  };
};

const mapDispatchToProps = { resetNotification, getCms, logoutUser };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(aboutUsStyle)(DashboardPage));