/*eslint-disable*/
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import parser from 'react-html-parser'
import Link from '@material-ui/core/Link';
import Check from '@material-ui/icons/Check';
import Info from '@material-ui/icons/Info';
// sections for this page

import errorPageStyle from "assets/jss/material-kit-pro-react/views/errorPageStyles.js";
import { resetNotification, emailVerify } from '../../redux/action'




class EmailVerifyPage extends Component {
    constructor(props) {
        super(props);
        this.setCardAnimation = this.setCardAnimation.bind(this);
        this.state = {
            cardAnimaton: "cardHidden",
            notification: false,
            token: ""
        }
    }
    setCardAnimation(val = "") {
        this.setState({ cardAnimaton: val })
    }

    componentDidMount() {
        let ths = this;
        setTimeout(function () {
            ths.setCardAnimation("");
        }, 700);
        console.log("this.props.match", this.props.match);
        if (this.props.match && this.props.match.params && this.props.match.params.id) {
            this.setState({
                token: this.props.match.params.id
            }, () => {
                this.props.emailVerify({ token: this.state.token });
            })
        }
    }
    setNotify(val = true) {
        console.log('in set notification', val);

        this.setState({ notification: val })
        let ths = this;
        setTimeout(() => {
            ths.setState({ notification: false })
        }, 2000)
        // this.props.resetNotification();
    }
    componentWillReceiveProps(props) {
        if (props.email_msg && !props.email_status) {
            setTimeout(() => {
                this.props.history.push('/dashboard')
            }, 3000);
        }
    }
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.contentCenter}>
                {
                    !this.props.email_status ?
                        <GridContainer>
                            <GridItem md={12}>
                                <h1 className={classes.title}><Check className={classes.checkIcon} /></h1>
                                {/* <h2 className={`${classes.subTitle} ${classes.greenColor}`}>{this.props.email_msg}</h2> */}
                                <h2 className={`${classes.subTitle} ${classes.greenColor}`}>{this.props.email_msg}</h2>
                            </GridItem>
                        </GridContainer> :
                         <GridContainer>
                            <GridItem md={12}>
                                <h1 className={classes.title}><Info className={classes.infoIcon} /></h1>
                                <h2 className={`${classes.subTitle} ${classes.blackColor}`}>{this.props.email_msg}</h2>
                            </GridItem>
                        </GridContainer>
                }
            </div>
        );
    }
}

EmailVerifyPage.propTypes = {
    classes: PropTypes.object
};
const mapStateToProps = state => {
    console.log('in maptoprops:======>', state.auth);
    return {
        shownotification: state.auth.shownotification,
        email_status: state.auth.email_status,
        loginerror: state.auth.loginerror,
        notification_message: state.auth.notification_message,
        email_msg: state.auth.email_msg,

    };
};

const mapDispatchToProps = { resetNotification, emailVerify };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(errorPageStyle)(EmailVerifyPage));