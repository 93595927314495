import React from "react";
// import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import Link from '@material-ui/core/Link';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import navbarsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/navbarsStyle.js";
import profileImage from "assets/img/apple-icon.png";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
// core components
import styles from "assets/jss/material-kit-pro-react/components/headerStyle.js";

import TopRightIcon from "assets/img/top-right-icon.png";
import { whiteColor } from "assets/jss/material-kit-pro-react";
import logo from "assets/img/mantra_logo.png";

const useStyles = makeStyles(styles);
const navbarStyle = makeStyles(navbarsStyle);

export default function Header(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const classes = useStyles();
  const navbarClasses = navbarStyle();
  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });
  const handleDropdownHeader = (e) => {
    console.log("E:", e);

    if (e === "Logout") {
      console.log("In logout");

      handleLogout();
    } else if (e === "Login") {
      props.history.push('/login')
    } else if(e === "Profile"){
      props.history.push('/edit-profile')
    }  else if(e === "Change Password"){
      props.history.push('/change-password')
    }
  }
  const handleLogin = () => {
    props.history.push('/signup')
  }
  const redirectDah = ()=>{
    props.history.push('/')
  }
  const handleLogout = () => {
    console.log("props==========>", props);
    props.handleLogout();
  }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleClose = () =>{
   var doc =  document.getElementsByClassName("banner")[0];
    doc.classList.add("db-none");
  }
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;

    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const { color, links, brand, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });
  console.log("USER", props);
  let dropdownlist_array = []
  var token = localStorage.getItem('token');
  if (token) {

    dropdownlist_array =localStorage.getItem('guest_user_id') ? ["Logout"] : ["Profile","Change Password","Logout"]
  } else {
    dropdownlist_array = ["Login"]
  }
  return (
    <>
    <div className="banner"><Close onClick={handleClose}/>We plan to dedicate <span>1 million</span> mantras to protect world from <span>Covid-19</span>. We appreciate your <span>devotion.</span></div>
    <Card className={classes.topTitle}>
      <CardHeader>
        {/* <Link href="/" style={{color : "#000"}}> */}
          
          <h2 className={`${classes.textCenter} ${classes.h2}`} style={{cursor : "pointer"}} onClick={()=>redirectDah()}>
          <img src={logo} width='50'/> <strong>MantraVerse<span className={classes.beta}>BETA</span></strong>
          </h2>
        {/* </Link> */}
        {/* <Button className={classes.logOut} size='sm' simple color="success" onClick={() => handleLogout()}><i className="material-icons">login</i></Button> */}

        {

          localStorage.getItem('token') ?
            <div className={classes.logOut}>
              <CustomDropdown
                left
                caret={false}
                hoverColor="success"
                buttonText={
                  <img className={navbarClasses.img} src={TopRightIcon}></img>
                }
                buttonProps={{
                  className:
                    navbarClasses.navLink + " " + navbarClasses.imageDropdownButton,
                  color: "transparent"
                }}
                dropdownList={dropdownlist_array}
                onClick={e => handleDropdownHeader(e)}
                color="success"
              />
            </div>
            :
            // <div className = {classes.buttoncolor} style={{color : "#fff"}}>
            <Button size="lg" className={classes.buttoncolor} onClick={() => handleLogin()}>Sign Up</Button>
          // </div>
          // 
        }


      </CardHeader>
    </Card>
    </>
  );
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  links: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};
