import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import Container from "@material-ui/core/Container";
// @material-ui/icons
import moment from "moment";
import AddAlert from "@material-ui/icons/AddAlert";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Accordion from "components/Accordion/Accordion.js";
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";
import { Component } from "react";
import Link from '@material-ui/core/Link';
import image from "assets/img/bg7.jpg";
import { Grid } from "@material-ui/core";
import { blackColor } from 'assets/jss/material-kit-pro-react.js';
import { dashboard, getMantraCount, updateMantrCount, addSession, resetNotification, logoutUser } from '../../redux/action'



class DashboardPage extends Component {
    constructor(props) {
        super(props);
        this.setCardAnimation = this.setCardAnimation.bind(this);
        this.state = {
            devotee_name: "",
            active_devotees_across_globe: 0,
            todaysession_totalmantra: 0,
            email: "",
            emailState: "",
            password: "",
            passwordState: "",
            cardAnimaton: "cardHidden",
            notification: false,
            is_token: 0
        }
    }

    componentDidMount() {
        let ths = this;
        setTimeout(function () {
            ths.setCardAnimation("");
        }, 700);
        var token = localStorage.getItem('token');
        if (token) {
            this.setState({
                token: 1
            })
        }
        var user_obj = JSON.parse(localStorage.getItem('user_obj'));
        this.setState({
            devotee_name: (user_obj && user_obj.first_name) ? `${user_obj.first_name} ${user_obj.last_name}` : (user_obj && user_obj.user_id) ? `Devotee${user_obj.user_id}` : localStorage.getItem('randomname')
        });
        var session_time = localStorage.getItem('session_time');
        var current_time = moment().format("YYYY-MM-DD HH:mm");
        if (localStorage.getItem('session_time')) {
           
            var diff = moment.duration(moment(current_time, "YYYY-MM-DD HH:mm").diff(moment(session_time, "YYYY-MM-DD HH:mm"))).asMinutes();
            if (diff < 30) {
                this.props.dashboard();
            } else {
                this.props.logoutUser(this.props.history);
            }
        } else{
            this.props.dashboard();
        }

    }

    setNotify(val = true) {
        console.log('in set notification', val);

        this.setState({ notification: val })
        let ths = this;
        setTimeout(() => {
            ths.setState({ notification: false })
        }, 2000)
        // this.props.resetNotification();
    }

    verifyLength(val, length) {
        if (val.length >= length) {
            return true;
        }
        return false;
    };
    verifyEmail(val) {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(val)) {
            return true;
        }
        return false;
    }
    setpasswordState(val = "") {
        this.setState({ passwordState: val })
    }
    setemailState(val = "") {
        this.setState({ emailState: val })
    }
    setCardAnimation(val = "") {
        this.setState({ cardAnimaton: val })
    }
    setemail(val = "") {
        this.setState({ email: val })
    }
    setpassword(val = "") {
        this.setState({ password: val })
    }
    onSubmit(val = {}) {
        console.log('Test', val, this.props);
        // this.setNotify() ;
        if (this.state.emailState === "") {
            this.setemailState("error");
        }
        if (val.email) {
            // this.props.loginUser(val,this.props.history);
            this.props.history.push('/dashboard')
        }
    }
    handleLogout = () => {
        this.props.logoutUser(this.props.history);
    }
    componentWillReceiveProps(props) {
        if (props.active_devotees_across_globe) {
            this.setState({
                active_devotees_across_globe: props.active_devotees_across_globe,
                todaysession_totalmantra: props.todaysession_totalmantra
            })
        }
    }
    render() {
        const { email, password, cardAnimaton, emailState, passwordState } = this.state;
        const { classes } = this.props;
        const dashboard_res = this.props.dashboard_data;
        console.log("tis,props", dashboard_res)
        return (
            <div>
                <div
                    className={classes.pageHeader}
                // style={{
                //     backgroundImage: "url(" + image + ")",
                //     backgroundSize: "cover",
                //     backgroundPosition: "top center"
                // }}
                >
                    {/* <Card className={classes.topTitle}>
                        <CardHeader><h2 className={classes.textCenter}>
                            <strong>MantraVerse</strong></h2>
                            <Button className={classes.logOut} color='rose' size='lg' onClick={()=>this.handleLogout()}>Logout</Button>
                        </CardHeader>
                    </Card> */}
                    <Header
                        handleLogout={() => this.handleLogout()}
                        history={this.props.history}
                    />

                    <Container fixed>
                        <Grid item xs={12}>

                            {localStorage.getItem('randomname') && this.state.token && <h3 className={`${classes.textCenter} ${classes.grayColor} ${classes.h3Style} ${classes.textSize}`}>Your ID : <span className={classes.blackColor}>{this.state.devotee_name}</span></h3>}
                            <NavLink to={"/statistics"} className={classes.navLink}><p className={`${classes.devoteSub} `}> Active Devotees Across Globe : {this.state.active_devotees_across_globe}
                                {localStorage.getItem('randomname') && this.state.token &&
                                    <KeyboardArrowRight style={{ "verticalAlign": "top"}} className={`${classes.icons}`} />
                                } </p></NavLink>
                                <p className={`${classes.devoteSub} `}> Total Mantra: {this.state.todaysession_totalmantra}</p>
                            {
                                this.props.dashboard_data && this.props.dashboard_data.length > 0 &&
                                <Accordion
                                    collapses={[
                                        {
                                            is_token: (localStorage.getItem("token") ? 1 : 0),
                                            content_san: (this.props.dashboard_data && this.props.dashboard_data.length > 0) ? this.props.dashboard_data[0].content_san : "",
                                            history: this.props.history,
                                            title: (this.props.dashboard_data && this.props.dashboard_data.length > 0) ? this.props.dashboard_data[0].title : "",
                                            content_en: (this.props.dashboard_data && this.props.dashboard_data.length > 0) ? this.props.dashboard_data[0].content_en : "",
                                            content_hi: (this.props.dashboard_data && this.props.dashboard_data.length > 0) ? this.props.dashboard_data[0].content_hin : "",
                                            content_guj: (this.props.dashboard_data && this.props.dashboard_data.length > 0) ? this.props.dashboard_data[0].content_guj : "",
                                            audio: (this.props.dashboard_data && this.props.dashboard_data.length > 0) ? this.props.dashboard_data[0].filename : "",
                                            meaning_en: (this.props.dashboard_data && this.props.dashboard_data.length > 0) ? this.props.dashboard_data[0].meaning_en : "",
                                            meaning_hi: (this.props.dashboard_data && this.props.dashboard_data.length > 0) ? this.props.dashboard_data[0].meaning_hin : "",
                                            meaning_guj: (this.props.dashboard_data && this.props.dashboard_data.length > 0) ? this.props.dashboard_data[0].meaning_guj : "",
                                            display_time: (this.props.dashboard_data && this.props.dashboard_data.length > 0) ? this.props.dashboard_data[0].display_time : "",
                                            id: (this.props.dashboard_data && this.props.dashboard_data.length > 0) ? this.props.dashboard_data[0].id : "",
                                        }
                                    ]
                                    }
                                />
                            }
                        </Grid>
                    </Container>
                </div>
                {/* <Container fixed className='generalFooter'>
                    <Grid container className='left-centerInResponsive'>
                        <Grid item lg={4} md={12} xs={12}>
                            &copy; Copyrights are reserved.
                        </Grid>
                        <Grid item lg={4} md={12} xs={12} className={classes.textCenter}>
                            <div className={classes.footer}>
                                <Link href="/user-agreement" className={classes.footerLinks}>User Agreement</Link>
                                <Link href="/privacy-policy" className={classes.footerLinks}>Privacy</Link>
                                <Link href="/about-us" className={classes.footerLinks}>About</Link>
                            </div>
                        </Grid>
                        <Grid item lg={4} md={12} xs={12} className='right-centerInResponsive'>
                        <p>Created by <b>Intuz</b> in association with <b>UinUniverse</b>. </p>
                        </Grid>
                    </Grid>
                </Container> */}
                <Footer ishomepage={true} />
            </div>
        );
    }
}

DashboardPage.propTypes = {
    classes: PropTypes.object
};
const mapStateToProps = state => {
    console.log('in maptoprops:======>', state.auth);
    return {
        user: state.auth.user,
        shownotification: state.auth.shownotification,
        loginerror: state.auth.loginerror,
        notification_message: state.auth.notification_message,
        dashboard_data: state.auth.dashboard,
        active_devotees_across_globe: state.auth.active_devotees_across_globe,
        todaysession_totalmantra:state.auth.todaysession_totalmantra
    };
};

const mapDispatchToProps = { dashboard, getMantraCount, updateMantrCount, addSession, resetNotification, logoutUser };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(shoppingCartStyle)(DashboardPage));