import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import { API_URL } from '../../constants/defaultValues';

import { 
    LIST_ACTIVEDEVOTEE_STATISTICS
 } from "../actionconstant";

import { 
    logoutUser,    
    listActiveDevoteeStatisticsFail,
    listActiveDevoteeStatisticsSuccess,
    resetActiveDevoteeNotification
 } from '../action';

function* listActiveDevoteeStatistics({payload}){
    const { history } = payload;
    let token = (localStorage.getItem('token'))?localStorage.getItem('token'):"";
    let headers = { headers: { token: `${token}` } }
    try{
        const response = yield axios.post(`${API_URL}/user/getstats`,payload.data,headers)
        if(response.data.status !== -1){
            if(response.data.status){
                yield put(listActiveDevoteeStatisticsSuccess(response.data))
            }else{
                if(response.data.status === -2){
                    history.push('/login');
                } else{
                    yield put(listActiveDevoteeStatisticsFail(response.data))
                    yield call(delay,2000);
                    yield put(resetActiveDevoteeNotification());

                }
            }
        }else{            
            yield put(listActiveDevoteeStatisticsFail(response.data))
            yield call(delay,2000);
            yield put(resetActiveDevoteeNotification());
            yield put(logoutUser(history))
            history.push('/');
        }
    }catch(error){
        console.log("error",error)
    }
}


export function* watchListDevoteeStatistics(){
    yield takeEvery(LIST_ACTIVEDEVOTEE_STATISTICS,listActiveDevoteeStatistics)
}

const delay = time => new Promise(resolve => setTimeout(resolve, time));

export default function* rootSaga(){
    yield all([
        fork(watchListDevoteeStatistics)
    ])
}