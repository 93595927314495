import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from "@material-ui/core/MenuItem";
// @material-ui/icons
import Snackbar from "components/Snackbar/SnackbarContent.js";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import classNames from "classnames";
import moment from "moment";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import FormControl from "@material-ui/core/FormControl";
// changesv1
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import ListItemText from "@material-ui/core/ListItemText";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import AddAlert from "@material-ui/icons/AddAlert";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Accordion from "components/Accordion/Accordion.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";
import { Component } from "react";
import Link from '@material-ui/core/Link';
import image from "assets/img/bg7.jpg";
import { Grid } from "@material-ui/core";
import { blackColor } from 'assets/jss/material-kit-pro-react.js';
import { resetNotification, logoutUser, fetchCountries, fetchStates, getProfile, updateProfile } from '../../redux/action'



class EditProfilePage extends Component {
    constructor(props) {
        super(props);
        this.editProfile = this.editProfile.bind(this);
        // this.props.getProfile({"user_id" : this.props.user},this.props.history)
        this.state = {
            countries: [],
            states: [],
            profile: {},
            user_id: "",
            first_name: "",
            first_name_state: "",
            last_name: "",
            last_name_state: "",
            email: "",
            address: "",
            address_state: "",
            phone: "",
            phone_state: "",
            country: 0,
            country_state: "",
            state: 0,
            state_state: "",
            phoneerr: "", firstnameerr: "", lastnameerr: "", addresserr: "", counteryerr: "", stateerr: "",
            user_obj: {}
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            countries: (props.countries) ? props.countries : [],
            states: (props.states) ? props.states : []
        };
    }

    componentDidMount() {
        this.setState({
            user_obj: JSON.parse(localStorage.getItem('user_obj'))
        }, () => {
            this.setState({
                profile: (this.state.user_obj) ? this.state.user_obj : {},
                user_id: (this.state.user_obj) ? (this.state.user_obj['user_id'] ? this.state.user_obj['user_id'] : "") : "",
                first_name: (this.props.user_obj) ? (this.props.user_obj['first_name'] ? this.props.user_obj['first_name'] : "") : "",
                last_name: (this.props.user_obj) ? (this.props.user_obj['last_name'] ? this.props.user_obj['last_name'] : "") : "",
                email: (this.state.user_obj) ? (this.state.user_obj['email'] ? this.state.user_obj['email'] : "") : "",
                address: (this.props.user_obj) ? (this.props.user_obj['address'] ? this.props.user_obj['address'] : "") : "",
                phone: (this.props.user_obj) ? (this.props.user_obj['phone'] ? this.props.user_obj['phone'] : "") : "",
                country: (this.props.user_obj) ? (this.props.user_obj['country'] ? this.props.user_obj['country'] : 0) : 0,
                state: (this.props.user_obj) ? (this.props.user_obj['state'] ? this.props.user_obj['state'] : 0) : 0
            })
        })
        console.log("this.state.user_obj===============>", this.state.user_obj);
        this.props.fetchCountries({ "type": "countries" });
        let country_id = (this.props.user_obj) ? (this.props.user_obj['country'] ? this.props.user_obj['country'] : "") : ""
        if (country_id !== "") {
            this.props.fetchStates({ "type": "states", "id": country_id });
        }

    }

    verifyString(val, len = 1) {
        if (val.trim().length >= len) {
            return true;
        }
        return false;
    }
    verifyPhone(val, len = 1) {
        // console.log("phone:",isNaN(val.trim()));

        if (val.trim().length >= len && (!isNaN(val.trim()))) {
            return true
        }
        return false;
    }
    verifyEmail(val) {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(val)) {
            return true;
        }
        return false;
    }
    setfirst_name(val = "") {
        this.setState({ first_name: val })
    }
    setfirst_name_state(val = "") {
        // if(val==="error"){
        //     this.setState({
        //       firstnameerr : "Please Enter First Name"
        //     })
        //   }
        this.setState({ first_name_state: val })
    }


    setlast_name(val = "") {
        this.setState({ last_name: val })
    }
    setlast_name_state(val = "") {
        // if(val==="error"){
        //     this.setState({
        //       lastnameerr : "Please Enter Last Name"
        //     })
        //   }
        this.setState({ last_name_state: val })
    }

    setphone(val = "") {
        this.setState({ phone: val })
    }
    setphone_state(val = "") {
        // if(val==="error"){
        //     this.setState({
        //       phoneerr : "Please Enter Phone Number"
        //     })
        //   }
        this.setState({ phone_state: val })
    }

    setaddress(val = "") {
        this.setState({ address: val })
    }
    setaddress_state(val = "") {
        // if(val==="error"){
        //     this.setState({
        //       addresserr : "Please Enter Address"
        //     })
        //   }
        this.setState({ address_state: val })
    }

    setcountry(val = "") {
        this.setState({ country: val })
    }
    setcountry_state(val = "") {
        // if(val==="error"){
        //     this.setState({
        //       counteryerr : "Please Select Country"
        //     })
        //   }
        this.setState({ country_state: val })
    }

    setstate(val = "") {
        this.setState({ state: val })
    }
    setstate_state(val = "") {
        // if(val==="error"){
        //     this.setState({
        //       stateerr : "Please Select State"
        //     })
        //   }
        this.setState({ state_state: val })
    }
    setemailState(val = "") {
        this.setState({ emailState: val })
    }
    setemail(val = "") {
        this.setState({ email: val })
    }
    handleCountryChange = (country) => {
        // console.log("Country Change", country)
        // if(country.target.value === 0){
        //     this.setState({
        //         counteryerr : "Please Select Country",
        //         state : 0
        //     })
        // } else{
        //     this.setState({
        //         counteryerr : "",
        //         state : 0
        //     })
        // }
        this.props.fetchStates({ "type": "states", "id": country.target.value });
        if (country.target.value == 0) {
            this.setState({ country: country.target.value, state: 0 })
        } else {
            this.setState({ country: country.target.value })
        }
    }

    handleStateChange = (state) => {
        console.log("State Change", state)
        // if(state.target.value === 0){
        //     this.setState({
        //         stateerr : "Please Select State"
        //     })
        // } else{
        //     this.setState({
        //         stateerr : ""
        //     })
        // }
        this.setState({ state: state.target.value })
        // this.setState({ contentLanguage: event, content, meaning, meaningLanguage: event })
    }
    editProfile(e) {
        e.preventDefault();
        let { user_id, first_name, last_name, phone, address, country, state } = this.state;
        let ths = this;
        console.log("this.state=================>", this.state);
        // if (!this.verifyString(this.state.first_name, 3)) {
        //     this.setfirst_name_state("error");
        // }
        // if (!this.verifyString(this.state.last_name, 3)) {
        //     this.setlast_name_state("error");
        // }
        // if (!this.verifyString(this.state.address, 5)) {
        //     this.setaddress_state("error");
        // }
        // if (!this.verifyPhone(this.state.phone, 8)) {
        //     this.setphone_state("error");
        // }
        // if(state === 0){
        //     this.setstate_state("error")
        // }
        // if(country === 0){
        //     this.setcountry_state("error")
        // }
        // if (this.verifyString(this.state.first_name, 3) && this.verifyString(this.state.last_name, 3) && this.verifyString(this.state.address, 5) && this.verifyPhone(this.state.phone, 8) && country !== 0 && state !== 0) {
        console.log("UPDATE:", first_name, last_name, phone, address, country, state)
        this.props.updateProfile({
            user_id, first_name, last_name, phone, address, country, state
        }, this.props.history)
        this.setfirst_name_state();
        this.setlast_name_state();
        this.setaddress_state();
        this.setphone_state();
        this.setcountry_state();
        this.setstate_state();
        setTimeout(() => {
            ths.props.getProfile({ "user_id": user_id }, ths.props.history)
        }, 2000)
        // }
    }
    onSubmit(val = {}) {
        console.log('Test', val, this.props);
        if (this.state.emailState === "") {
            this.setemailState("error");
        }
        if (val.email) {
            // this.props.loginUser(val,this.props.history);
            this.props.history.push('/dashboard')
        }
    }
    handleLogout = () => {
        this.props.logoutUser(this.props.history);
    }
    handleClose = () => {
        this.props.history.push('/dashboard')
    }
    render() {
        const { countries, country, state, states, phoneerr, firstnameerr, lastnameerr, addresserr, counteryerr, stateerr } = this.state;
        const { classes } = this.props;
        console.log("tis,props", countries.length, this.state)
        return (
            <div>
                <div
                    className={classes.pageHeader}
                >
                    <Header
                        handleLogout={() => this.handleLogout()}
                        history={this.props.history}
                    />

                    <Container fixed>
                        <Grid item xs={12}>
                            <Card style={{ top: "30px" }}>
                                <form className={classes.form} onSubmit={e => this.editProfile(e)}>
                                    <CardHeader color="success" icon>
                                        {/* <CardIcon color="rose">
                                <PermIdentity />
                            </CardIcon> */}
                                        <h4 className={classes.cardIconTitle}>
                                            Edit Profile
                            </h4>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={4} className= "mb-11">
                                                <CustomInput
                                                    labelText="Email address"
                                                    id="email-address"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        onChange: event => {
                                                            if (this.verifyString(event.target.value, 3)) {
                                                                this.setemailState("success");
                                                            } else {
                                                                this.setemailState("error");
                                                            }
                                                            this.setemail(event.target.value);
                                                        },
                                                        value: this.state.email,
                                                        type: "email",
                                                        disabled: true
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4} className= "mb-11">
                                                <CustomInput
                                                    errorlabelText={phoneerr}
                                                    success={this.state.phone_state === "success"}
                                                    error={this.state.phone_state === "error"}
                                                    labelText="Phone"
                                                    id="phone"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        onChange: event => {
                                                            if (this.verifyPhone(event.target.value, 8)) {
                                                                this.setphone_state("success");
                                                            } else {
                                                                this.setphone_state("error");
                                                            }
                                                            this.setphone(event.target.value);
                                                        },
                                                        value: this.state.phone,
                                                        type: "text"
                                                    }}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={4} className= "mb-11">
                                                <CustomInput
                                                    errorlabelText={firstnameerr}
                                                    success={this.state.first_name_state === "success"}
                                                    error={this.state.first_name_state === "error"}
                                                    labelText="First Name"
                                                    id="first-name"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        onChange: event => {
                                                            if (this.verifyString(event.target.value, 3)) {
                                                                this.setfirst_name_state("success");
                                                            } else {
                                                                this.setfirst_name_state("error");
                                                            }
                                                            this.setfirst_name(event.target.value);
                                                        },
                                                        value: this.state.first_name,
                                                        type: "text"
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4} className= "mb-11">
                                                <CustomInput
                                                    errorlabelText={lastnameerr}
                                                    success={this.state.last_name_state === "success"}
                                                    error={this.state.last_name_state === "error"}
                                                    labelText="Last Name"
                                                    id="last-name"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        onChange: event => {
                                                            if (this.verifyString(event.target.value, 3)) {
                                                                this.setlast_name_state("success");
                                                            } else {
                                                                this.setlast_name_state("error");
                                                            }
                                                            this.setlast_name(event.target.value);
                                                        },
                                                        value: this.state.last_name,
                                                        type: "text"
                                                    }}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={4} className="mb-40">
                                                {/* <label>Select Country</label> */}
                                                <FormControl fullWidth className={classes.selectFormControl}>
                                                    <Select
                                                        MenuProps={{
                                                            className: classes.selectMenu,
                                                            // fullWidth: true
                                                        }}
                                                        classes={{
                                                            select: classes.select
                                                        }}
                                                        onChange={this.handleCountryChange}
                                                        inputProps={{
                                                            name: "country",
                                                            id: "country",
                                                            value: country
                                                        }}
                                                    >
                                                        <MenuItem
                                                            classes={{
                                                                root: classes.selectMenuItem,
                                                                selected: classes.selectMenuItemSelected
                                                            }}
                                                            value={0}
                                                        >
                                                            Select Country
                                                </MenuItem>
                                                        {(this.state.countries.length > 0) ? this.state.countries.map((country) => <MenuItem
                                                            classes={{
                                                                root: classes.selectMenuItem,
                                                                selected: classes.selectMenuItemSelected
                                                            }}
                                                            value={country.id}
                                                        >
                                                            {country.name}
                                                        </MenuItem>) : []}
                                                    </Select>
                                                </FormControl>
                                                {counteryerr ? <FormHelperText id="country" className={classes.labelRootError}>Please Select Country</FormHelperText> : ""}
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4} className="mb-40">
                                                <FormControl fullWidth className={classes.selectFormControl}>
                                                    <Select
                                                        MenuProps={{
                                                            className: classes.selectMenu,
                                                            // fullWidth: true
                                                        }}
                                                        classes={{
                                                            select: classes.select
                                                        }}
                                                        value={state}
                                                        onChange={this.handleStateChange}
                                                        inputProps={{
                                                            name: "state",
                                                            id: "state"
                                                        }}
                                                    >
                                                        <MenuItem
                                                            classes={{
                                                                root: classes.selectMenuItem,
                                                                selected: classes.selectMenuItemSelected
                                                            }}
                                                            value={0}
                                                        >
                                                            Select State
                                                </MenuItem>
                                                        {(this.state.states.length > 0 && country && country !== 0 && country !== '') ? this.state.states.map((state) => <MenuItem
                                                            classes={{
                                                                root: classes.selectMenuItem,
                                                                selected: classes.selectMenuItemSelected
                                                            }}
                                                            value={state.id}
                                                        >
                                                            {state.name}
                                                        </MenuItem>) : []}
                                                    </Select>
                                                </FormControl>
                                                {stateerr ? <FormHelperText id="state" className={classes.labelRootError}>Please Select State</FormHelperText> : ""}

                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4} style={{ marginTop: "-22px" }}>
                                                <CustomInput
                                                    errorlabelText={addresserr}
                                                    success={this.state.address_state === "success"}
                                                    error={this.state.address_state === "error"}
                                                    labelText="City"
                                                    id="address"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        onChange: event => {
                                                            if (this.verifyString(event.target.value, 5)) {
                                                                this.setaddress_state("success");
                                                            } else {
                                                                this.setaddress_state("error");
                                                            }
                                                            this.setaddress(event.target.value);
                                                        },
                                                        value: this.state.address,
                                                        type: "text"
                                                    }}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                        <Button color="success" className={classes.updateProfileButton} type="submit">
                                            Update Profile
                                    </Button>
                                        <Button color="success" className={classes.updateProfileButton} onClick={() => this.handleClose()}>
                                            Cancel
                                    </Button>
                                    </CardBody>
                                </form>
                            </Card>
                        </Grid>
                    </Container>
                    <div
                        className={classes.container}
                    >
                        {
                            this.props.notification_message !== "" &&
                            <Snackbar
                                place="tr"
                                color={(this.props.loginerror) ? "danger" : "success"}
                                icon={AddAlert}
                                message={`${this.props.notification_message}`}
                                open={this.props.shownotification}
                                closeNotification={() => {

                                }}
                                close
                            />
                        }
                    </div>
                </div>
                <Footer />
                {/* <Container fixed className='generalFooter'>
                    <Grid container className='left-centerInResponsive'>
                        <Grid item lg={4} md={12} xs={12}>
                            &copy; Copyrights are reserved.
                        </Grid>
                        <Grid item lg={4} md={12} xs={12} className={classes.textCenter}>
                            <div className={classes.footer}>
                                <Link href="/user-agreement" className={classes.footerLinks}>User Agreement</Link>
                                <Link href="/privacy-policy" className={classes.footerLinks}>Privacy</Link>
                                <Link href="/about-us" className={classes.footerLinks}>About</Link>
                            </div>
                        </Grid>
                        <Grid item lg={4} md={12} xs={12} className='right-centerInResponsive'>
                            <p>Created by Intuz in association with UinUniverse. </p>
                        </Grid>
                    </Grid>
                </Container> */}
            </div>
        );
    }
}

EditProfilePage.propTypes = {
    classes: PropTypes.object
};
const mapStateToProps = state => {
    // console.log('Profile page props:======>', state.auth);
    return {
        user: state.auth.user,
        user_obj: state.auth.user_obj,
        countries: state.auth.countries,
        states: state.auth.states,
        profile: state.auth.profile,
        shownotification: state.auth.shownotification,
        loginerror: state.auth.loginerror,
        notification_message: state.auth.notification_message,
        dashboard_data: state.auth.dashboard,
        active_devotees_across_globe: state.auth.active_devotees_across_globe
    };
};

const mapDispatchToProps = { resetNotification, logoutUser, fetchCountries, fetchStates, getProfile, updateProfile };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(shoppingCartStyle)(EditProfilePage));