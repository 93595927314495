import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
// changesv1
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import ListItemText from "@material-ui/core/ListItemText";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import AddAlert from "@material-ui/icons/AddAlert";
import Link from '@material-ui/core/Link';
import Snackbar from "components/Snackbar/SnackbarContent.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { registerUser, registerGuestUser, resetNotification } from '../../redux/action'
import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";
import { Component } from "react";
import image from "assets/img/bg7.jpg";
import Footer from "components/Footer/Footer.js";



class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.setCardAnimation = this.setCardAnimation.bind(this);
    this.state = {
      email: "",
      emailState: "",
      password: "",
      passwordState: "",
      cardAnimaton: "cardHidden",
      notification: false,
      emailerrorText : ""
    }
  }

  componentDidMount() {
    let ths = this;
    setTimeout(function () {
      ths.setCardAnimation("");
    }, 700);
  }
  setNotify(val = true) {
    console.log('in set notification', val);

    this.setState({ notification: val })
    let ths = this;
    setTimeout(() => {
      ths.setState({ notification: false })
    }, 2000)
    // this.props.resetNotification();
  }

  verifyLength(val, length) {
    if (val.length >= length) {
      return true;
    }
    return false;
  };
  verifyEmail(val) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(val.trim())) {
      return true;
    } else{
      this.setState({
        emailerrorText : "Please Enter Valid Email"
      });
      return false;
    }
  }
  setpasswordState(val = "") {
    this.setState({ passwordState: val })
  }
  setemailState(val = "") {
    if(val==="error"){
      this.setState({
        emailerrorText : "Please Enter Valid Email"
      })
    }
    this.setState({ emailState: val })
  }
  setCardAnimation(val = "") {
    this.setState({ cardAnimaton: val })
  }
  setemail(val = "") {
    this.setState({ email: val.trim() })
  }
  setpassword(val = "") {
    this.setState({ password: val })
  }
  onSubmit(e,val = {}) {
    e.preventDefault();
    if (this.state.emailState === "") {
      this.setemailState("error");
    }
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    if (val.email) {
      
      if (emailRex.test(val.email.trim())) {
        this.props.registerUser(val, this.props.history);
      }else{
        this.setemailState("error");
      }
    }
  }
  onGuestSubmit = (e, val = {}) => {
    console.log("here ==>");
    e.preventDefault();
    // if (this.state.emailState === "") {
    //   this.setemailState("error");
    // }
    // var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    // if (val.email) {
      
    //   if (emailRex.test(val.email.trim())) {
        this.props.registerGuestUser(val, this.props.history);
    //   }else{
    //     this.setemailState("error");
    //   }
    // }
  }


  render() {
    const { email, password, cardAnimaton, emailState, passwordState,emailerrorText } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <div
          className={classes.pageHeader}
        // style={{
        //   backgroundImage: "url(" + image + ")",
        //   backgroundSize: "cover",
        //   backgroundPosition: "top center"
        // }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <form className={classes.form} onSubmit={(e) => this.onSubmit(e,{ email })}>
                    <CardHeader>
                      <h2 className={`${classes.textCenter} ${classes.blockTitle}`}>SignUp</h2>
                    </CardHeader>
                    <CardBody signup>
                      <CustomInput
                      errorlabelText = {emailerrorText}
                        success={emailState === "success"}
                        error={emailState === "error"}
                        labelText="Email..."
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{

                          onChange: ((e) => {
                            if (this.verifyEmail(e.target.value)) {
                              this.setemailState("success");
                            } else {
                              this.setemailState("error");
                            }
                            this.setemail(e.target.value)
                          })
                        }}
                      />
                    </CardBody>
                    <div className={`${classes.textCenter} ${classes.logSignFoot}`}>
                      <Button type="submit" color="success" size="lg">
                        SignUp
                    </Button>
                    {
                      this.props.register_message && this.props.registe_msg_status && this.props.register_message == 1 &&
                        <div style={{ paddingTop: "20px" }}>
                          <Link href="/login" style={{ textDecoration:"none" }}>
                              <Button type="button" color="success" size="lg" style={{ marginRight:"20px",textDecoration:"none" }}>
                                  Login
                              </Button>
                          </Link>
                          <Link href="/forgot-password"  style={{ textDecoration:"none" }}>
                            <Button type="button" color="success" size="lg">
                                Forgot Password
                            </Button>
                            </Link>
                        </div>
                    }
                      <p style={{ paddingTop: "20px" }}>Already have an account? <Link href="/login" style={{color : "#4caf50"}}><b>Login</b></Link></p>
                       <Link href="/login" onClick={(e) => this.onGuestSubmit(e)} style={{color : "#4caf50", fontSize: "19px"}}><b>Signup as Guest User</b></Link>

                    </div>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
            {
              this.props.notification_message &&
              <Snackbar
                place="tr"
                color={(this.props.loginerror) ? "danger" : "success"}
                icon={AddAlert}
                message={`${this.props.notification_message}`}
                open={this.props.shownotification}
                closeNotification={() => {
                  this.setNotify(false);
                }}
                close
              />
            }
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object
};
const mapStateToProps = state => {
  console.log('in maptoprops:===>insignup', state);
  return {
    user: state.auth.user,
    shownotification: state.auth.shownotification,
    loginerror: state.auth.loginerror,
    notification_message: state.auth.notification_message,
    registe_msg_status:state.auth.registe_msg_status,
    register_message:state.auth.register_message,
  };
};

const mapDispatchToProps = { registerUser, registerGuestUser, resetNotification };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPageStyle)(LoginPage));