import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import moment from "moment";
// import swal from 'swal';
import { 
    LOGIN_USER,FETCH_DASHBOARD_DATA,
    FETCH_MANTRA_COUNT,REGISTER_USER, REGISTER_GUEST_USER,
    UPDATE_MANTRA_COUNT,ADD_NEW_SESSION,LOGOUT_USER,FETCH_CMS,
    EMAIL_VERIFY,FETCH_USER_PROFILE,UPDATE_USER_PROFILE, FETCH_COUNTRIES, FETCH_STATES,
    CHANGE_PASSWORD,RESET_PASSWORD,FORGOT_PASSWORD,VERIFY_RESET_TOKEN
 } from '../actionconstant';

 import { API_URL } from '../../constants/defaultValues'
 import { 
            resetNotification,
            loginSuccess,
            loginError,
            dashboardSuccess,
            dashboardError,
            updateMantrCountError,
            updateMantrCountSuccess,
            getMantraCountError,
            getMantraCountSuccess,
            addSessionError,
            addSessionSuccess,
            registerUserError,
            registerUserSuccess,
            registerGuestUserSuccess,
            getCmsError,
            getCmsSuccess,
            emailVerifyError,
            emailVerifySuccess,
            getProfileError,
            getProfileSuccess,
            updateProfileError,
            updateProfileSuccess,
            fetchCountriesSucccess,
            fetchCountriesFail,
            fetchStatesSucccess,
            fetchStatesFail,
            changePasswordError,
            changePasswordSuccess,
            forgotPasswordSuccess,
            forgotPasswordError,
            resetPasswordError,
            verifyResetTokenError,
            resetPasswordSuccess,
            verifyResetTokenSuccess
        } from './authactions';

 function* loginWithEmailPassword({ payload }) {
    const { history } = payload;
    try {
        console.log("In Saga",payload);
        
        // const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
        const response = yield axios.post(`${API_URL}/user/login`, payload.user)
        
        console.log("Response",response.data);
        if (response.data.status === false) {
            yield put(loginError(response.data))
            yield call(delay,5000)
            yield put(resetNotification())
              
        } else {
            yield localStorage.removeItem('guest_user_id');
            yield localStorage.setItem('token', response.data.token);
            yield localStorage.setItem('user_id', JSON.stringify(response.data.data.user_id));
            yield localStorage.setItem('user_obj', JSON.stringify(response.data.data));
            yield localStorage.setItem('session_time',moment().format("YYYY-MM-DD HH:mm"));
            yield localStorage.setItem('session_id',response.data.session_id);
            yield localStorage.setItem('randomname',response.data.data.randomname);
            yield put(loginSuccess(response.data));
            yield put(resetNotification());
            if(response.data.data.is_password_changed && response.data.data.is_password_changed !== null && response.data.data.is_password_changed !== undefined){
                history.push('/dashboard');
            } else{
                history.push('/change-password')
            }
        }
    } catch (error) {
        console.log('login error : ', error)
    }
}
function* dashboard({ payload }) {
    var postdata = payload.user
    console.log("=============================")
    try {
        const response = yield axios.post(`${API_URL}/user/getmantra`)
        console.log("Response=====>",response.data);
        if (response.data.status === false) {
            yield put(dashboardError(response.data))
            yield call(delay,4000)
            yield put(resetNotification())
            
        } else {
            yield put(dashboardSuccess(response.data));
            yield call(delay,2000);
            yield put(resetNotification());
           
        }
    } catch (error) {
        console.log('forgot password error : ', error)
    }
}

function* createnewSession({ payload }) {
    var postdata = payload.user
    try {
        const response = yield axios.post(`${API_URL}/user/createnewsession`, postdata)
        console.log("Response",response.data);
        if (response.data.status === false) {
            yield put(addSessionError(response.data))
            yield call(delay,4000)
            yield put(resetNotification())
            
        } else {
            yield put(addSessionSuccess(response.data));
            yield call(delay,2000);
            yield put(resetNotification());
           
        }
    } catch (error) {
        console.log('forgot password error : ', error)
    }
}
function* registerUser({ payload }) {
    var postdata = payload.user
    const { history } = payload;
    try {
        const response = yield axios.post(`${API_URL}/user/signup`, postdata)
        console.log("Response=====> register",response.data);
        if (response.data.status === false) {
            yield put(registerUserError(response.data))
            yield call(delay,4000)
            yield put(resetNotification())
            
        } else {
            yield localStorage.removeItem('guest_user_id');
            yield localStorage.setItem('token', response.data.token);
            yield localStorage.setItem('user_id', JSON.stringify(response.data.data.user_id));
            yield localStorage.setItem('user_obj', JSON.stringify(response.data.data));
            yield localStorage.setItem('session_time',moment().format("YYYY-MM-DD HH:mm"));
            yield localStorage.setItem('session_id',response.data.session_id);
            yield localStorage.setItem('randomname',response.data.data.randomname);
            yield put(registerUserSuccess(response.data));
            yield call(delay,2000);
            yield put(resetNotification());
            history.push('/dashboard');
           
        }
    } catch (error) {
        console.log('forgot password error : ', error)
    }
}

function* registerGuestUser({ payload }) {
    console.log("in saga");
    var postdata = payload.user
    postdata.user_id = localStorage.getItem('guest_user_id') ? localStorage.getItem('guest_user_id') : "";
    const { history } = payload;
    try {
        const response = yield axios.post(`${API_URL}/user/guest_signup`, postdata)
        console.log("Response=====> register",response.data);
        if (response.data.status === false) {
            yield put(registerUserError(response.data))
            yield call(delay,4000)
            yield put(resetNotification())
            
        } else {
            yield localStorage.setItem('token', response.data.token);
            yield localStorage.setItem('guest_user_id', JSON.stringify(response.data.data.user_id));
            yield localStorage.setItem('user_id', JSON.stringify(response.data.data.user_id));
            yield localStorage.setItem('user_obj', JSON.stringify(response.data.data));
            yield localStorage.setItem('session_time',moment().format("YYYY-MM-DD HH:mm"));
            yield localStorage.setItem('session_id',response.data.session_id);
            yield localStorage.setItem('randomname',response.data.data.randomname);
            yield put(registerGuestUserSuccess(response.data));
            yield call(delay,2000);
            yield put(resetNotification());
            history.push('/dashboard');
           
        }
    } catch (error) {
        console.log('forgot password error : ', error)
    }
}

function* logout({ payload }) {
    const { history } = payload;
    try {
        localStorage.removeItem('token');
      localStorage.removeItem('user_id');
      localStorage.removeItem('session_id');
      localStorage.removeItem('randomname');
      localStorage.removeItem('session_time');
      localStorage.removeItem('user_obj');
      history.push('/login');
    } catch (error) {
        console.log('forgot password error : ', error)
    }
}

function* updateMantraCount({ payload }) {
    var postdata = payload.user
    const { history } = payload;
    try {
        const response = yield axios.post(`${API_URL}/user/updatemantracount`, postdata,{ headers: {
            "token" : localStorage.getItem('token')
          }})
        console.log("Response",response.data);
        if (response.data.status === false) {
            yield put(updateMantrCountError(response.data))
            yield call(delay,4000)
            yield put(resetNotification())
            
        } else {
            if((response.data.status) === -2){
                history.push('/login');
                yield put(updateMantrCountError(response.data));
                yield call(delay,2000);
                yield put(resetNotification());
            } else{
                yield put(updateMantrCountSuccess(response.data));
                yield call(delay,2000);
                yield put(resetNotification());
            }
          
           
        }
    } catch (error) {
        console.log('forgot password error : ', error)
    }
}

function* getMantraCount({ payload }) {
    var postdata = payload.user
    const { history } = payload;
    try {
        const response = yield axios.post(`${API_URL}/user/getmantracount`, postdata,{ headers: {
            "token" : localStorage.getItem('token')
          }});
        console.log("Response in mantra count",response.data);
        console.log("this.propsa.history========>",history);
        if (response.data.status === false) {
            yield put(getMantraCountError(response.data))
            yield call(delay,4000)
            yield put(resetNotification())
            
        } else {
            if(response.data.status === -2){
                yield put(getMantraCountError(response.data));
                yield call(delay,2000);
                yield put(resetNotification());
                history.push('/login')
            } else{
                yield put(getMantraCountSuccess(response.data));
                yield call(delay,2000);
                yield put(resetNotification());
            }
           
           
        }
    } catch (error) {
        console.log('forgot password error : ', error)
    }
}

function* getCms({ payload }) {
    var postdata = payload.user
    try {
        const response = yield axios.post(`${API_URL}/master/get-cms`, postdata);
        console.log("Response",response.data);
        if (response.data.status === false) {
            yield put(getCmsError(response.data))
            yield call(delay,4000)
            yield put(resetNotification())
            
        } else {
            yield put(getCmsSuccess(response.data));
            yield call(delay,2000);
            yield put(resetNotification());
           
        }
    } catch (error) {
        console.log('forgot password error : ', error)
    }
}

function* emailVerify({ payload }) {
    var postdata = payload.user
    try {
        const response = yield axios.post(`${API_URL}/user/emailverify`, postdata);
        console.log("Response",response.data);
        if (response.data.status === false) {
            yield put(emailVerifyError(response.data))
            yield call(delay,4000)
            yield put(resetNotification())
            
        } else {
            yield put(emailVerifySuccess(response.data));
            yield call(delay,2000);
            yield put(resetNotification());
           
        }
    } catch (error) {
        console.log('forgot password error : ', error)
    }
}
function* getProfile({ payload }) {
    var postdata = payload.user
    const { history } = payload;
    let token = (localStorage.getItem('token'))?localStorage.getItem('token'):"";
    let headers = { headers: { token: `${token}` } }
    try {
        const response = yield axios.post(`${API_URL}/user/getprofile`, postdata,headers);
        console.log("GET PROFILE RESPONSE",response.data);
        if (response.data.status === false) {
            yield put(getProfileError(response.data))
            yield call(delay,4000)
            yield put(resetNotification())
            
        } else {
            if(response.data.status === -2){
                yield put(getProfileError(response.data));
                yield call(delay,2000);
                yield put(resetNotification());
                history.push('/login');
            } else{
                yield put(getProfileSuccess(response.data));
                yield localStorage.setItem('user_obj', JSON.stringify(response.data.data));
                yield call(delay,2000);
                yield put(resetNotification());
                
            }
           
        }
    } catch (error) {
        console.log('forgot password error : ', error)
    }
}

function* updateProfile({ payload }) {
    var postdata = payload.user
    const { history } = payload;
    let token = (localStorage.getItem('token'))?localStorage.getItem('token'):"";
    let headers = { headers: { token: `${token}` } }
    try {
        const response = yield axios.post(`${API_URL}/user/updateprofile`, postdata,headers);
        console.log("Response",response.data);
        if (response.data.status === false) {
            yield put(updateProfileError(response.data))
            yield call(delay,4000)
            yield put(resetNotification())
            
        } else {
            if(response.data.status === -2){
                yield put(updateProfileError(response.data));
                yield call(delay,2000);
                yield put(resetNotification());
                history.push('/login')
            } else{
                console.log("saga in update profile",response.data);
                yield put(updateProfileSuccess(response.data));
                yield call(delay,2000);
                yield put(resetNotification());

            }
           
        }
    } catch (error) {
        console.log('forgot password error : ', error)
    }
}

function* fetchCountries({payload}){
    try {
        const response = yield axios.post(`${API_URL}/master/get-master`, payload);
        console.log("Response",response.data);
        if (response.data.status === false) {
            yield put(fetchCountriesFail(response.data));
            yield call(delay,2000);
            yield put(resetNotification());            
        } else {
            
            yield put(fetchCountriesSucccess(response.data))
            yield call(delay,4000)
            yield put(resetNotification())
        }
    } catch (error) {
        console.log('forgot password error : ', error)
    }   
}

function* fetchStates({payload}){
    try {
        const response = yield axios.post(`${API_URL}/master/get-master`, payload);
        console.log("Response",response.data);
        if (response.data.status === false) {
            yield put(fetchStatesFail(response.data));
            yield call(delay,2000);
            yield put(resetNotification());
            
        } else {
            yield put(fetchStatesSucccess(response.data))
            yield call(delay,4000)
            yield put(resetNotification())
           
        }
    } catch (error) {
        console.log('forgot password error : ', error)
    }   
}

function* changePassword({payload}){
    const { history } = payload;
    try {
        const response = yield axios.post(`${API_URL}/user/changepassworduser`, payload.data,{ headers: {
            "token" : localStorage.getItem('token')
          }});
        console.log("Response",response.data);
        if (!response.data.status) {
            yield put(changePasswordError(response.data));
            yield call(delay,2000);
            yield put(resetNotification());
            
        } else {
            if(response.data.status === -2){
                yield put(changePasswordError(response.data));
                yield call(delay,2000);
                yield put(resetNotification());
                history.push('/login');
            } else{
                yield put(changePasswordSuccess(response.data))
                yield call(delay,4000)
                yield put(resetNotification())
               history.push('/')
            }
        }
    } catch (error) {
        console.log('forgot password error : ', error)
    }   
}

function* forgotPassword({payload}){
    const { history } = payload;
    try {
        const response = yield axios.post(`${API_URL}/user/forgotpassworduser`, payload.data);
        console.log("Response",response.data);
        if (!response.data.status) {
            yield put(forgotPasswordError(response.data));
            yield call(delay,2000);
            yield put(resetNotification());
            
        } else {
            yield put(forgotPasswordSuccess(response.data))
            yield call(delay,4000)
            yield put(resetNotification())
           history.push('/login')
        }
    } catch (error) {
        console.log('forgot password error : ', error)
    }   
}
function* resetPassword({payload}){
    const { history } = payload;
    try {
        const response = yield axios.post(`${API_URL}/user/resetpassworduser`, payload.data);
        console.log("Response",response.data);
        if (!response.data.status) {
            yield put(resetPasswordError(response.data));
            yield call(delay,2000);
            yield put(resetNotification());
            
        } else {
            yield put(resetPasswordSuccess(response.data))
            yield call(delay,4000)
            yield put(resetNotification())
           history.push('/login')
        }
    } catch (error) {
        console.log('forgot password error : ', error)
    }   
}

function* verifyResetToken({payload}){
    const { history } = payload;
    try {
        const response = yield axios.post(`${API_URL}/user/verifypasswordtokenuser`, payload.data);
        console.log("Response",response.data);
        if (!response.data.status) {
            yield put(verifyResetTokenError(response.data));
            yield call(delay,2000);
            yield put(resetNotification());
            
        } else {
            yield put(verifyResetTokenSuccess(response.data))
            yield call(delay,4000)
            yield put(resetNotification())
        //    history.push('/')
        }
    } catch (error) {
        console.log('forgot password error : ', error)
    }   
}


export function* watchAddSession() {
    yield takeEvery(ADD_NEW_SESSION, createnewSession);
}
 
export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}
export function* watchDashboard() {
    console.log("testse123");
    yield takeEvery(FETCH_DASHBOARD_DATA, dashboard);
}

export function* watchRegisterUser() {
    console.log("testse123");
    yield takeEvery(REGISTER_USER, registerUser);
}

export function* watchRegisterGuestUser() {
    console.log("testse123");
    yield takeEvery(REGISTER_GUEST_USER, registerGuestUser);
}

export function* watchGetMantraCount() {
    console.log("testse123");
    yield takeEvery(FETCH_MANTRA_COUNT, getMantraCount);
}

export function* watchUpdateMantraCount() {
    console.log("testse123");
    yield takeEvery(UPDATE_MANTRA_COUNT, updateMantraCount);
}
export function* watchLogout() {
    console.log("testse123");
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchCMS() {
    yield takeEvery(FETCH_CMS,getCms);
}

export function* watchEmailVerify() {
    yield takeEvery(EMAIL_VERIFY,emailVerify);
}

export function* watchUpdateProfile() {
    yield takeEvery(UPDATE_USER_PROFILE,updateProfile);
}

export function* watchGetProfile() {
    yield takeEvery(FETCH_USER_PROFILE,getProfile);
}


export function* watchFetchCountries(){
    yield takeEvery(FETCH_COUNTRIES,fetchCountries)
}

export function* watchFetchStates(){
    yield takeEvery(FETCH_STATES,fetchStates)
}

export function* watchChangePassword(){
    yield takeEvery(CHANGE_PASSWORD,changePassword)
}

export function* watchForgotPassword(){
    yield takeEvery(FORGOT_PASSWORD,forgotPassword)
}

export function* watchResetPassword(){
    yield takeEvery(RESET_PASSWORD,resetPassword)
}

export function* watchVerifyResetToken(){
    yield takeEvery(VERIFY_RESET_TOKEN,verifyResetToken)
}

const delay = time => new Promise(resolve => setTimeout(resolve, time));


export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchDashboard),
        fork(watchAddSession),
        fork(watchRegisterUser),
        fork(watchRegisterGuestUser),
        fork(watchGetMantraCount),
        fork(watchUpdateMantraCount),
        fork(watchLogout),
        fork(watchCMS),
        fork(watchEmailVerify),
        fork(watchGetProfile),
        fork(watchUpdateProfile),
        fork(watchFetchStates),
        fork(watchFetchCountries),
        fork(watchChangePassword),
        fork(watchForgotPassword),
        fork(watchResetPassword),
        fork(watchVerifyResetToken)
    ]);
}