import React, { Component } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
// import AudioPlayer from "react-h5-audio-player";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import withStyles from "@material-ui/core/styles/withStyles";
// import Select from 'react-select-v1';
// import 'react-select-v1/dist/react-select.css';
import Switch from '@material-ui/core/Switch';
import Button from "components/CustomButtons/Button.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import parser from 'react-html-parser'
import _ from "lodash";
// @material-ui/icons
import ExpandMore from "@material-ui/icons/ExpandMore";

import styles from "assets/jss/material-kit-pro-react/components/accordionStyle.js";
import { Grid, Hidden } from "@material-ui/core";
import { container } from 'assets/jss/material-kit-pro-react.js';
import { getMantraCount, updateMantrCount, addSession, resetNotification, logoutUser } from '../../redux/action'
import { createTrue } from "typescript";
class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalplayedcount:0,
      Is_Muted:true,
      Auto_Stop_After:false,
      session_count: 0,
      total_session_count: 0,
      active: [-1],
      single: false,
      collapses: [],
      main_song: false,
      language: [{ label: "English", value: 0 }, { label: "Hindi", value: 1 }, { label: "Gujarati", value: 2 }],
      contentLanguage: 0,
      defaultcountplay: 108,
      meaningLanguage: 0,
      playaudio: true,
      playrepeat: true,
      content: "",
      meaning: "",
      audio: "",
      main_content: "",
      display_time: "00",
      autoPlay: false,
      time: 0,
      temp_display_time: 0,
      is_token: 0,
      checked: false,
      timerAudio: 0,
      is_repeat: 0,
      is_universal_sync: 1,
      song_played_count: 3,
      universal_checked: false,
      disableToggle: false,
      showTimer: false
    };
    this.intervalClass = null;
    this.rap = null;
  };
  handleContentLanguage = (contentLanguage) => {
    var content = "", meaning = "", event = contentLanguage.target.value;
   
    if (event === 1) {
      content = this.props.collapses[0].content_hi;
     
    } else if (event === 0) {
      content = this.props.collapses[0].content_en;
     
    } else if (event === 2) {
      content = this.props.collapses[0].content_guj;
      
    }
    this.setState({ contentLanguage: event, content })
  }
  handleplaycountchange = (contentLanguage) => {
    var content = "", meaning = "", event = contentLanguage.target.value;
    
 
    this.setState({totalplayedcount:0})
    this.setState({ defaultcountplay: event })
  }
  
  handleMeaningLanguage = (meaningLanguage) => {
    
    var meaning = "", event = meaningLanguage.target.value;
    if (event === 1) {
      meaning = this.props.collapses[0].meaning_hi;
    } else if (event === 0) {
      meaning = this.props.collapses[0].meaning_en;
    } else if (event === 2) {
      meaning = this.props.collapses[0].meaning_guj;
    }
    this.setState({ meaningLanguage: event, meaning })
  }
  setActive = active => {
    if (active.length > 0) {
      this.setState({
        active: active,
        single: false,
       
      })
    } else {
      this.setState({
        active: [active],
        single: true,
       
      })
    }
  }
  handleChange = panel => {
    
    let newArray;
    var active = this.state.active;
    if (this.state.single) {
      if (active[0] === panel) {
        newArray = [];
      } else {
        newArray = [panel];
      }
    } else {
      
      if (active.indexOf(panel) === -1) {
        newArray = [panel];
      } else {
        newArray = [...active];
        newArray.splice(active.indexOf(panel), 1);
      }
    }
    this.setActive(newArray)
  }
 
  componentDidMount() {
   
    
    this.setState({
      collapses: this.props.collapses,
      content: this.props.collapses[0].content_en,
      meaning: this.props.collapses[0].meaning_en,
      audio: this.props.collapses[0].audio,
      main_content: this.props.collapses[0].content_san,
     
      temp_display_time: this.props.collapses[0].display_time,
      is_token: this.props.collapses[0].is_token
    }, () => {
      if (this.state.is_token) {
        var session_time = localStorage.getItem('session_time');
        var current_time = moment().format("YYYY-MM-DD HH:mm");
        var diff = moment.duration(moment(current_time, "YYYY-MM-DD HH:mm").diff(moment(session_time, "YYYY-MM-DD HH:mm"))).asMinutes();
       if (diff < 30) {

          localStorage.setItem('session_time', moment().format("YYYY-MM-DD HH:mm"));
          this.props.getMantraCount({ user_id: localStorage.getItem('user_id'), mantra_id: this.props.collapses[0].id, user_session_id: localStorage.getItem('session_id') }, this.props.collapses[0].history)
        } else {
          this.props.logoutUser(this.props.collapses[0].history);
        }
        this.setState({
          showTimer: true
        })
        this.displayTime(this.state.temp_display_time);
      }
     
    });

  }
  handleCanPlay = (e) => {
    
   
  }
  handlePlayEnd = () => {
    
    if (this.state.defaultcountplay > this.state.totalplayedcount) {
      this.rap.play();
    }
    if (this.state.defaultcountplay == this.state.totalplayedcount) {
     
      //auto off the video
      this.setState({checked:0,Auto_Stop_After:false})
      
    }
    
  }
  handlePlay = () => {
    
    var totalplayed = this.state.totalplayedcount;
    this.setState({totalplayedcount:totalplayed+1})
    if(this.state.checked){
     
      this.props.updateMantrCount({ user_id: localStorage.getItem('user_id'), mantra_id: this.props.collapses[0].id, user_session_id: localStorage.getItem('session_id') }, this.props.collapses[0].history);
      setTimeout(() => {
        this.props.getMantraCount({ user_id: localStorage.getItem('user_id'), mantra_id: this.props.collapses[0].id, user_session_id: localStorage.getItem('session_id') }, this.props.collapses[0].history);
      }, 2000);
    }else{
     
    }

  }
  handleSwitch = e => {
    if (this.state.is_token) {
      
        this.setState({defaultcountplay:108})
        this.setState({totalplayedcount:0})
       
        if (this.state.defaultcountplay > this.state.totalplayedcount) {
         
        }
        if(!this.state.checked){
          this.setState({checked:1})
          this.setState({Is_Muted:false,Auto_Stop_After:true})
        }else{
          this.setState({checked:0})
          this.setState({Is_Muted:true,Auto_Stop_After:false})
        }
    } else {
      this.props.collapses[0].history.push('/signup')
    }
  }


  displayTime = (e) => {
    let currentSecond = new Date().getSeconds();
    let givenTimeSlot = Number(this.state.temp_display_time)
    let remainingTime = 0
    for (let i = 1; i < 11; i++) {
      if (givenTimeSlot * i <= 60) {
        if (currentSecond <= givenTimeSlot * i) {
          remainingTime = givenTimeSlot * i
          break;
        }
      }
    }
    
    let timerTimeInSec = remainingTime - currentSecond
    this.setState({
      timerAudio: timerTimeInSec
    }, () => {
      this.setTimer(timerTimeInSec)
    })
  }
  setTimer(timeInSec) {

    this.intervalClass = setInterval(
      () => {
        
        if (this.state.timerAudio > 0) {
          this.setState((prevState) => (
            { timerAudio: prevState.timerAudio - 1 }));
        } else {
          
          clearInterval(this.intervalClass)
          if (this.state.timerAudio == 0) {
          
            this.setState({
              autoPlay: true,
              showTimer: false,
              main_song: true,
              playaudio:true,
              song_played_count: 108,
              playrepeat: true,
              is_repeat:1,
             
            }, () => {
           
                this.rap.play();
              
            })
          }
        }

      },
      1000
    );
  }



  componentWillReceiveProps(props) {
    if (props.mantra_count && props.mantra_count.length > 0) {
      this.setState({
        session_count: props.mantra_count[0].total_this_session,
        total_session_count: props.mantra_count[0].total_count
      })
    }
  }

  render() {
    const { active, collapses } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.root}  >
        <iframe src="silence.mp3" allow="autoplay" id="audio" style={{ display: "none" }}></iframe>
        {collapses.map((prop, key) => {
          return (
            <ExpansionPanel
              expanded //={active === 0 || active.indexOf(key) !== -1}
              onChange={() => this.handleChange(key)}
              key={key}
              classes={{
                root: classes.expansionPanel,
                expanded: classes.expansionPanelExpanded
              }}
            >
              <React.Fragment>
                           <audio ref={(a) => this.rap = a} style={{ visibility: "hidden" }}  autoPlay={this.state.autoPlay ? true : false} onEnded={() => this.handlePlayEnd()} onPlay={() => this.handlePlay()} preload="auto"  muted={this.state.Is_Muted}>
                              <source src={this.state.audio} type="audio/mpeg" />
                            </audio>
                            
                          </React.Fragment>
              <ExpansionPanelSummary
                // expandIcon={<ExpandMore />}
                classes={{
                  root: `${classes.expansionPanelSummary} ${
                    classes["primary" + "ExpansionPanelSummary"]
                    }`,
                  expanded: `${classes.expansionPanelSummaryExpaned} ${
                    classes["primary" + "ExpansionPanelSummaryExpaned"]
                    }`,
                  content: classes.expansionPanelSummaryContent,
                  expandIcon: classes.expansionPanelSummaryExpandIcon,
                }}
              >
                <h4 className={`${classes.title} ${classes.paddingBoth}`}>{prop.title}</h4>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                <Grid container>
                  <Grid item xs={12} className={`${classes.paddingBoth} ${classes.mainContent}`}>
                    {parser(this.state.main_content)}
                  </Grid>
                  <Grid item xs={12} className={`${classes.paddingBoth} ${classes.subContent}`}>
                    {parser(this.state.content)}
                  </Grid>
                  {/* <Hidden mdDown> */}
                  
                  <Grid item md={9} xs={12}></Grid>
                  {/* </Hidden> */}
                  <Grid item md={3} xs={12}>
                    <div className={classes.langBtn}>
                     
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={this.state.contentLanguage}
                        onChange={this.handleContentLanguage}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select"
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={0}
                        >
                          English
                      </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={1}
                        >
                          Hindi
                      </MenuItem>
                        <MenuItem
                          cl asses={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={2}
                        >
                          Gujarati
                      </MenuItem>
                      </Select>
                    </div>

                  </Grid>
                </Grid>
              
                <Grid container>
                  <Grid item md={6} xs={12}>
                  </Grid>
                  <Grid item md={6} xs={12} className={classes.textRight} style={{ padding: "5px 15px" }}>

                    <div className={classes.playBlock} >
                      {
                          this.state.showTimer ?
                            
                              <span style={{ color: "red" }}>{" Syncing time with other devotees. Starting in 00:" + (this.state.timerAudio < 10 ? "0" + this.state.timerAudio : this.state.timerAudio)}</span> 
                            : null
                          
                      }
                      {" "}
                      Audio{" "}
                      {
                        this.state.checked ?
                          <Button size="sm" className={classes.buttoncolor} color="success" onClick={e => this.handleSwitch(e)} >Stop</Button>
                        :
                          <Button size="sm" className={classes.buttoncolor} onClick={e => this.handleSwitch(e)} >Start</Button>
                      }
                      
                      {/* <Switch thumbcolor={"#4caf50"} checked={this.state.checked} disabled={this.state.disableToggle} className={classes.switchcolor} onClick={e => this.handleSwitch(e)} /> */}
                      {
                       
                      
                      }
                    </div>
                    <Grid item xs={12} className={classes.textRight} style={{ color: "gray", marginBottom:"10px" }}>Click Start for mantra counter</Grid>
                    <Grid container >
                      <Grid item xs={7} className={classes.paddingBoth}>
                        {
                          this.state.is_token ?
                            <>
                              <p>This session <b>{this.state.session_count}</b></p>
                              <p>Total <b>{localStorage.getItem("guest_user_id") ? this.state.session_count : this.state.total_session_count}</b></p>
                            </>
                            : null
                        }
                      </Grid>
                      { this.state.Auto_Stop_After ?
                       <>
                      <Grid item xs={5}>
                        Auto Stop After{'  '}
                        <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={this.state.defaultcountplay}
                        onChange={this.handleplaycountchange}
                        inputProps={{
                          name: "simpleSelect1",
                          id: "simple-select1"
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={11}
                        >
                          11
                      </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={21}
                        >
                          21
                      </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={51}
                        >
                          51
                      </MenuItem>
                      <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={108}
                        >
                          108
                      </MenuItem>
                      </Select>
                      </Grid>  
                      </>
                      : null
                      }
                     
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} className={`${classes.paddingBoth} ${classes.meanningContent}`}>
                    <h4><strong>Meaning</strong></h4>
                    {parser(this.state.meaning)}
                  </Grid>
                  {/* <Hidden mdDown> */}
                  <Grid item md={9} xs={12}></Grid>
                  {/* </Hidden> */}
                  <Grid item md={3} xs={12}>
                    <div className={classes.langBtn} style={{ marginTop: "45px" }}>
                      
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={this.state.meaningLanguage}
                        onChange={this.handleMeaningLanguage}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select"
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={0}
                        >
                          English
                      </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={1}
                        >
                          Hindi
                      </MenuItem>
                        <MenuItem
                          cl asses={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={2}
                        >
                          Gujarati
                      </MenuItem>
                      </Select>
                    </div>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })
        }
      </div >

    );
  }
};

Accordion.propTypes = {
  classes: PropTypes.object
};

Accordion.defaultProps = {
  active: -1,
  activeColor: "primary"
};

Accordion.propTypes = {
  // index of the default active collapse
  active: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)
  ]),
  collapses: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.node
    })
  ).isRequired,
  activeColor: PropTypes.oneOf([
    "primary",
    "secondary",
    "warning",
    "danger",
    "success",
    "info",
    "rose"
  ])
};

const mapStateToProps = state => {
  // console.log('in maptoprops:=========>', state);
  return {
    user: state.auth.user,
    shownotification: state.auth.shownotification,
    loginerror: state.auth.loginerror,
    notification_message: state.auth.notification_message,
    dashboard_data: state.auth.dashboard,
    mantra_count: state.auth.mantra_count
  };
};

const mapDispatchToProps = { getMantraCount, updateMantrCount, addSession, resetNotification, logoutUser };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Accordion));