import { 
    LOGIN_USER,
    LOGIN_USER_ERROR,
    LOGIN_USER_SUCCESS,
    FETCH_DASHBOARD_DATA,
    FETCH_DASHBOARD_DATA_ERROR,
    FETCH_DASHBOARD_DATA_SUCCESS,
    FETCH_MANTRA_COUNT,
    FETCH_MANTRA_COUNT_ERROR,
    FETCH_MANTRA_COUNT_SUCCESS,
    REGISTER_GUEST_USER,
    REGISTER_USER,
    REGISTER_USER_ERROR,
    REGISTER_USER_SUCCESS,
    REGISTER_GUEST_USER_SUCCESS,
    UPDATE_MANTRA_COUNT,
    UPDATE_MANTRA_COUNT_ERORR,
    UPDATE_MANTRA_COUNT_SUCCESS,
    ADD_NEW_SESSION,
    ADD_NEW_SESSION_ERROR,
    ADD_NEW_SESSION_SUCCESS,
    RESET_NOTIFICATION,
    LOGOUT_USER,
    FETCH_CMS,
    FETCH_CMS_ERROR,
    FETCH_CMS_SUCCESS,
    EMAIL_VERIFY,
    EMAIL_VERIFY_ERROR,
    EMAIL_VERIFY_SUCCESS,
    FETCH_USER_PROFILE,
    FETCH_USER_PROFILE_ERROR,
    FETCH_USER_PROFILE_SUCCESS,
    UPDATE_USER_PROFILE,
    UPDATE_USER_PROFILE_ERROR,
    UPDATE_USER_PROFILE_SUCCESS,
    FETCH_COUNTRIES,
    FETCH_COUNTRIES_FAIL,
    FETCH_COUNTRIES_SUCCESS,
    FETCH_STATES,
    FETCH_STATES_FAIL,
    FETCH_STATES_SUCCESS,
    CHANGE_PASSWORD_ERROR,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    RESET_PASSWORD,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_SUCCESS,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_ERROR, 
    FORGOT_PASSWORD_SUCCESS,
    VERIFY_RESET_TOKEN,
    VERIFY_RESET_TOKEN_ERROR,
    VERIFY_RESET_TOKEN_SUCCESS
 } from '../actionconstant';

 
 export const resetNotification=()=>({
    type:RESET_NOTIFICATION
}) 
export const loginUser =(user,history)=>({
    type: LOGIN_USER,
    payload: { user,history }
});

export const loginSuccess =(user)=>({
    type: LOGIN_USER_SUCCESS,
    payload: user
});

export const loginError =(data)=>({
    type: LOGIN_USER_ERROR,
    payload: data 
});



export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload : {history}
  });

export const dashboard =(user,history)=>({
    type: FETCH_DASHBOARD_DATA,
    payload: { user,history }
});

export const dashboardSuccess =(data)=>({
    type: FETCH_DASHBOARD_DATA_SUCCESS,
    payload: data
});

export const dashboardError =(data)=>({
    type: FETCH_DASHBOARD_DATA_ERROR,
    payload: data 
});

export const getMantraCount =(user,history)=>({
    type: FETCH_MANTRA_COUNT,
    payload: { user,history }
});

export const getMantraCountSuccess =(data)=>({
    type: FETCH_MANTRA_COUNT_SUCCESS,
    payload: data
});

export const getMantraCountError =(data)=>({
    type: FETCH_MANTRA_COUNT_ERROR,
    payload: data 
});

export const registerUser =(user,history)=>({
    type: REGISTER_USER,
    payload: { user,history }
});

export const registerGuestUser =(user,history)=>({
    type: REGISTER_GUEST_USER,
    payload: { user,history }
});

export const registerUserSuccess =(user)=>({
    type: REGISTER_USER_SUCCESS,
    payload: user
});

export const registerGuestUserSuccess =(user)=>({
    type: REGISTER_GUEST_USER_SUCCESS,
    payload: user
});

export const registerUserError =(data)=>({
    type: REGISTER_USER_ERROR,
    payload: data 
});

export const addSession =(user,history)=>({
    type: ADD_NEW_SESSION,
    payload: { user,history }
});

export const addSessionSuccess =(user)=>({
    type: ADD_NEW_SESSION_SUCCESS,
    payload: user
});

export const addSessionError =(data)=>({
    type: ADD_NEW_SESSION_ERROR,
    payload: data 
});

export const updateMantrCount =(user,history)=>({
    type: UPDATE_MANTRA_COUNT,
    payload: { user,history }
});

export const updateMantrCountSuccess =(user)=>({
    type: UPDATE_MANTRA_COUNT_SUCCESS,
    payload: user
});

export const updateMantrCountError =(data)=>({
    type: UPDATE_MANTRA_COUNT_ERORR,
    payload: data 
});


export const getCms =(user,history)=>({
    type: FETCH_CMS,
    payload: { user,history }
});

export const getCmsSuccess =(user)=>({
    type: FETCH_CMS_SUCCESS,
    payload: user
});

export const getCmsError =(data)=>({
    type: FETCH_CMS_ERROR,
    payload: data 
});

export const emailVerify =(user,history)=>({
    type: EMAIL_VERIFY,
    payload: { user,history }
});

export const emailVerifySuccess =(data)=>({
    type: EMAIL_VERIFY_SUCCESS,
    payload: data
});

export const emailVerifyError =(data)=>({
    type: EMAIL_VERIFY_ERROR,
    payload: data 
});

export const getProfile =(user,history)=>({
    type: FETCH_USER_PROFILE,
    payload: { user,history }
});

export const getProfileSuccess =(data)=>({
    type: FETCH_USER_PROFILE_SUCCESS,
    payload: data
});

export const getProfileError =(data)=>({
    type: FETCH_USER_PROFILE_SUCCESS,
    payload: data 
});

export const updateProfile =(user,history)=>({
    type: UPDATE_USER_PROFILE,
    payload: { user,history }
});

export const updateProfileSuccess =(data)=>({
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload: data
});

export const updateProfileError =(data)=>({
    type: UPDATE_USER_PROFILE_ERROR,
    payload: data 
});

export const fetchCountries = (data) => ({
    type : FETCH_COUNTRIES,
    payload : data
})

export const fetchCountriesSucccess = (data) => ({
    type : FETCH_COUNTRIES_SUCCESS,
    payload : data
})

export const fetchCountriesFail = (data) => ({
    type : FETCH_COUNTRIES_FAIL,
    payload : data
})

export const fetchStates = (data) => ({
    type : FETCH_STATES,
    payload : data
})

export const fetchStatesSucccess = (data) => ({
    type : FETCH_STATES_SUCCESS,
    payload : data
})

export const fetchStatesFail = (data) => ({
    type : FETCH_STATES_FAIL,
    payload : data
})

export const changePassword = (data,history) => ({
    type : CHANGE_PASSWORD,
    payload : {data,history}
});

export const changePasswordSuccess = (data) => ({
    type : CHANGE_PASSWORD_SUCCESS,
    payload : data
});

export const changePasswordError = (data) => ({
    type : CHANGE_PASSWORD_ERROR,
    payload : data
});


export const forgotPassword = (data,history) => ({
    type : FORGOT_PASSWORD,
    payload : {data,history}
});

export const forgotPasswordSuccess = (data) => ({
    type : FORGOT_PASSWORD_SUCCESS,
    payload : data
});

export const forgotPasswordError = (data) => ({
    type : FORGOT_PASSWORD_ERROR,
    payload : data
});

export const resetPassword = (data,history) => ({
    type : RESET_PASSWORD,
    payload : {data,history}
});

export const resetPasswordSuccess = (data) => ({
    type : RESET_PASSWORD_SUCCESS,
    payload : data
});

export const resetPasswordError = (data) => ({
    type : RESET_PASSWORD_ERROR,
    payload : data
});

export const verifyResetToken = (data,history) => ({
    type : VERIFY_RESET_TOKEN,
    payload : {data,history}
});

export const verifyResetTokenSuccess = (data) => ({
    type : VERIFY_RESET_TOKEN_SUCCESS,
    payload : data
});

export const verifyResetTokenError = (data) => ({
    type : VERIFY_RESET_TOKEN_ERROR,
    payload : data
});