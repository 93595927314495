import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
// changesv1
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import ListItemText from "@material-ui/core/ListItemText";
// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
import AddAlert from "@material-ui/icons/AddAlert";
// import LockOutline from "@material-ui/icons/LockOutline";
import Link from '@material-ui/core/Link';
import Header from "components/Header/Header.js";
import { Grid } from "@material-ui/core";
// core components
import Snackbar from "components/Snackbar/SnackbarContent.js";
import GridContainer from "components/Grid/GridContainer.js";
import Container from "@material-ui/core/Container";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js"
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { changePassword, resetNotification, logoutUser } from '../../redux/action'

import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";

class ChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newpassword: "",
            newpasswordState: "",
            cardAnimaton: "cardHidden",
            notification: false,
            oldpassword: "",
            oldpasswordState: "",
            confirmpassword: "",
            confirmpasswordState: "",
            is_password_changed: 1,
            toast_message: "",
            oldpwderrorText: "",
            newpwderrorText: "",
            confirmpwdText: ""
        }
    }
    componentDidMount() {
        let ths = this;
        setTimeout(function () {
            ths.setCardAnimation("");
        }, 700);
        var user_obj = JSON.parse(localStorage.getItem('user_obj'));
        this.setState({
            is_password_changed: (user_obj.is_password_changed && user_obj.is_password_changed !== null && user_obj.is_password_changed !== undefined) ? user_obj.is_password_changed : 1
        });
    }
    setNotify(val = true) {
        console.log('in set notification', val);

        this.setState({ notification: val })
        let ths = this;
        setTimeout(() => {
            ths.setState({ notification: false })
        }, 2000);
    }

    setCardAnimation(val = "") {
        this.setState({ cardAnimaton: val })
    }
    verifyLength(val, length) {
        if (val.length >= length) {
            return true;
        }
        return false;
    };
    setnewpasswordState(val = "") {
        if (val === "error") {
            this.setState({
                newpwderrorText: "Please Enter Password"
            })
        }
        this.setState({ newpasswordState: val })
    }
    setoldpasswordState(val = "") {
        if (val === "error") {
            this.setState({
                oldpwderrorText: "Please Enter Old Password"
            })
        }
        this.setState({ oldpasswordState: val })
    }
    setconfirmpasswordState(val = "") {
        if (val === "error") {
            this.setState({
                confirmpwdText: (this.state.confirmpassword === "" ? "Please Enter Confirm Password" : "Confirm Password doesn't match")
            })
        }
        this.setState({ confirmpasswordState: val })
    }
    setnewpassword(val = "") {
        this.setState({ newpassword: val })
    }
    setoldpassword(val = "") {
        this.setState({ oldpassword: val })
    }
    setconfirmpassword(val = "") {
        this.setState({ confirmpassword: val })
    }
    verifyConfirmpassword(val, lenght = 1) {
        if (val.length >= lenght) {
            if (val === this.state.newpassword) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }
    onSubmit(e, val = {}) {
        console.log("this.porpas", this.props, this.state)
        e.preventDefault();
        if (this.state.newpasswordState === "") {
            this.setnewpasswordState("error");
        }
        if (this.state.oldpasswordState === "") {
            this.setoldpasswordState("error");
        }
        if (this.state.confirmpasswordState === "") {
            this.setconfirmpasswordState("error");
        }

        if (this.state.newpassword && this.state.oldpassword && this.state.newpassword === this.state.confirmpassword) {
            console.log("sdkjkfjklfnvkj")
            var data = {
                user_id: localStorage.getItem('user_id'),
                password: this.state.newpassword,
                currentpassword: this.state.oldpassword
            }
            this.props.changePassword(data, this.props.history);
            // this.setnewpassword();
            // this.setoldpassword();
            // this.setconfirmpassword();
            // this.setnewpasswordState();
            // this.setoldpasswordState();
            // this.setconfirmpasswordState();
        } else {
            if (this.state.confirmpassword !== this.state.newpassword) {
                this.setState({
                    toast_message: "Confirm Password doesn't matched"
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            toast_message: ""
                        })
                    }, 3000)
                });
            }
        }
    }

    onskip = () => {
        this.props.history.push('/');
    }
    handleLogout = () => {
        this.props.logoutUser(this.props.history);
    }
    handleClose = () =>{
        this.props.history.push('/dashboard')
    }
    render() {
        const { oldpassword, confirmpassword, newpassword, cardAnimaton, oldpasswordState, confirmpasswordState, newpasswordState, newpwderrorText, oldpwderrorText, confirmpwdText } = this.state;
        const { classes } = this.props;
        return (
            <div>
                <div
                    className={classes.pageHeader}
                >
                    <Header
                        handleLogout={() => this.handleLogout()}
                        history={this.props.history}
                    />

                    <Container fixed>
                        <Grid item xs={12}>
                            <form className={classes.form} onSubmit={(e)=>this.onSubmit(e,{ newpassword,oldpassword,confirmpassword})}>
                                <Card style={{top: "30px"}}>
                                    <CardHeader color="success" icon>
                                        {/* <CardIcon color="rose">
                            <PermIdentity />
                        </CardIcon> */}
                                        <h4 className={classes.cardIconTitle}>
                                            Change Password
                        </h4>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={4}>
                                                <CustomInput
                                                    errorlabelText={oldpwderrorText}
                                                    success={oldpasswordState === "success"}
                                                    error={oldpasswordState === "error"}
                                                    labelText="Old Password"
                                                    id="oldpassword"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{

                                                        type: "password",
                                                        autoComplete: "off",
                                                        onChange: ((e) => {
                                                            if (this.verifyLength(e.target.value, 1)) {
                                                                this.setoldpasswordState("success");
                                                            } else {
                                                                this.setoldpasswordState("error");
                                                            }
                                                            this.setoldpassword(e.target.value)
                                                        })
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4}>
                                                <CustomInput
                                                    errorlabelText={newpwderrorText}
                                                    success={newpasswordState === "success"}
                                                    error={newpasswordState === "error"}
                                                    labelText="New Password"
                                                    id="password"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{

                                                        type: "password",
                                                        autoComplete: "off",
                                                        onChange: ((e) => {
                                                            if (this.verifyLength(e.target.value, 1)) {
                                                                this.setnewpasswordState("success");
                                                            } else {
                                                                this.setnewpasswordState("error");
                                                            }
                                                            this.setnewpassword(e.target.value)
                                                        })
                                                    }}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={4}>
                                                <CustomInput
                                                    errorlabelText={confirmpwdText}
                                                    success={confirmpasswordState === "success"}
                                                    error={confirmpasswordState === "error"}
                                                    labelText="Confirm Password"
                                                    id="confirmpassword"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{

                                                        type: "password",
                                                        autoComplete: "off",
                                                        onChange: ((e) => {
                                                            if (this.verifyConfirmpassword(e.target.value, 1)) {
                                                                this.setconfirmpasswordState("success");
                                                            } else {
                                                                this.setconfirmpasswordState("error");
                                                            }
                                                            this.setconfirmpassword(e.target.value)
                                                        })
                                                    }}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                        <Button color="success" className={classes.updateProfileButton} type="submit">
                                            Change Password
                                    </Button>
                                    <Button color="success" className={classes.updateProfileButton} onClick={()=>this.handleClose()}>
                                        Cancel
                                    </Button>
                                    </CardBody>

                                </Card>
                            </form>
                        </Grid>
                    </Container>
                    {
                        this.props.notification_message !== "" &&
                        <Snackbar
                            place="tr"
                            color={(this.props.loginerror) ? "danger" : "success"}
                            icon={AddAlert}
                            message={`${this.props.notification_message}`}
                            open={this.props.shownotification}
                            closeNotification={() => {

                            }}
                            close
                        />
                    }
                    {
                        this.state.toast_message !== "" &&
                        <Snackbar
                            place="tr"
                            color={"danger"}
                            icon={AddAlert}
                            message={`${this.state.toast_message}`}
                            open={true}
                            closeNotification={() => {

                            }}
                            close
                        />
                    }
                </div>
                <Footer />
                {/* <Container fixed className='generalFooter'>
                <Grid container className='left-centerInResponsive'>
                    <Grid item lg={4} md={12} xs={12}>
                        &copy; Copyrights are reserved.
                    </Grid>
                    <Grid item lg={4} md={12} xs={12} className={classes.textCenter}>
                        <div className={classes.footer}>
                            <Link href="/user-agreement" className={classes.footerLinks}>User Agreement</Link>
                            <Link href="/privacy-policy" className={classes.footerLinks}>Privacy</Link>
                            <Link href="/about-us" className={classes.footerLinks}>About</Link>
                        </div>
                    </Grid>
                    <Grid item lg={4} md={12} xs={12} className='right-centerInResponsive'>
                        <p>Created by Intuz in association with UinUniverse. </p>
                    </Grid>
                </Grid>
            </Container> */}
            </div>
        );
    }

};

ChangePassword.propTypes = {
    classes: PropTypes.object
};
const mapStateToProps = state => {
    console.log('in maptoprops: in changepassword', state);
    return {
        user: state.auth.user,
        shownotification: state.auth.shownotification,
        loginerror: state.auth.loginerror,
        notification_message: state.auth.notification_message,
    };
};

const mapDispatchToProps = { changePassword, resetNotification, logoutUser };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(shoppingCartStyle)(ChangePassword));