import { all } from 'redux-saga/effects';
import authSagas from './auth/authsaga';
import activedevoteestatisticsSaga from './activedevoteestatistics/activedevoteestatisticssaaga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    activedevoteestatisticsSaga()
  ]);
}
