import { 
    LIST_ACTIVEDEVOTEE_STATISTICS,
    LIST_ACTIVEDEVOTEE_STATISTICS_SUCCESS,
    LIST_ACTIVEDEVOTEE_STATISTICS_FAIL,
    RESET_ACTIVEDEVOTEE_NOTIFICATION
} from '../actionconstant'

let initialState = {
    activedevotee_list : [],
    activedevotee_total_list_user : 0,
    activedevotee_total_user : 0,
    activedevotee_count1 : 0,
    activedevotee_count2 : 0,
    activedevotee_count3 : 0,
    activedevotee_count4 : 0,
    activedevotee_statistics:[],
    notification_message  : "",
    shownotification : false,
    activedevotee_error : false
}

const activeDevoteeReducer = (state=initialState,action) => {
  
    switch(action.type){        
        case LIST_ACTIVEDEVOTEE_STATISTICS:
            return state;
        case LIST_ACTIVEDEVOTEE_STATISTICS_SUCCESS:
            return {
                ...state,
                activedevotee_statistics : action.payload.data.data,
                activedevotee_count1 : action.payload.data.totaluser,
                activedevotee_count2 : action.payload.data.totalmantra,
                activedevotee_count3 : action.payload.data.todaysuser,
                activedevotee_count4 : action.payload.data.todaysuser_mantra,
            };

           
        case LIST_ACTIVEDEVOTEE_STATISTICS_FAIL:
            return {
                ...state,
                notification_message : action.payload.message,
                shownotification : true,
                activedevotee_error : true
            };
        case RESET_ACTIVEDEVOTEE_NOTIFICATION:
            return {
                ...state,
                notification_message : "",
                shownotification : false,
                activedevotee_error : false                
            }
        default:
            return state;
    }
}

export default activeDevoteeReducer;