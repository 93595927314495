import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from 'react-redux';

// import InfoOutline from "@material-ui/icons/InfoOutline";

// core components
import { Grid } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js";
import Header from "components/Header/Header.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody";




// import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import styles from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";
import { logoutUser ,resetActiveDevoteeNotification,listActiveDevoteeStatistics } from '../../redux/action'



class ActiveDevoteeStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    
      activedevotee_statistics : [],
      activedevotee_count1 : 0,
      activedevotee_count2 : 0,   
      activedevotee_count3 : 0,
      activedevotee_count4 : 0,      
      notification: false
    }
  } 
  componentDidMount(){  
    let user_id = localStorage.getItem('user_id');
    this.props.listActiveDevoteeStatistics({
      user_id:user_id,
    },this.props.history);  
  } 
  static getDerivedStateFromProps(props, state) {    
    console.log("props cattt",props);
    // console.log("props state cattt",state);      
      return {  
        activedevotee_statistics : (props.activedevotee_statistics)?props.activedevotee_statistics:[],
        activedevotee_count1: (props.activedevotee_count1)?props.activedevotee_count1:0,
        activedevotee_count2: (props.activedevotee_count2)?props.activedevotee_count2:0,
        activedevotee_count3: (props.activedevotee_count3)?props.activedevotee_count3:0,
        activedevotee_count4: (props.activedevotee_count4)?props.activedevotee_count4:0   
      };        
  }

  handleLogout = () => {
    this.props.logoutUser(this.props.history);
  }

  render() {
    const { classes } = this.props;
  //  console.log("this.props.activedevotee_statistics1111",this.props.activedevotee_statistics)
    const { activedevotee_count1,activedevotee_count2,activedevotee_count3,activedevotee_count4,activedevotee_statistics } = this.state;
    return (
       <div>
         <div>
                <div className={classes.pageHeader}>                    
                    <Header
                        handleLogout={() => this.handleLogout()}
                        history={this.props.history}
                    />

                    <Container fixed>
                        <Grid item xs={12}>
                            <GridContainer>
                              
                              <GridItem xs={12} sm={12} md={12} lg={12}>
                                <Card>
                                <CardHeader className={classes.cardTitle}>
                                  <h3>Active Devotee Statistics</h3>
                                </CardHeader>
                                <CardBody color="rose" stats icon>
                                    <GridContainer className={classes.statcardmargin}>
                                      <table width="100%">
                                          <tr>
                                            <th xs={6} sm={6} md={6} lg={6}></th>
                                            <th xs={2} sm={2} md={2} lg={2} align="left" >Users</th> 
                                            <th xs={2} sm={2} md={2} lg={2} align="left" >Mantras</th>
                                          </tr>
                                          <tr>
                                            <td xs={6} sm={6} md={6} lg={6}>Active</td>
                                            <td xs={2} sm={2} md={2} lg={2}>{activedevotee_count1}</td>
                                            <td xs={2} sm={2} md={2} lg={2}>{activedevotee_count2}</td>
                                          </tr>
                                          <tr>
                                            <td xs={6} sm={6} md={6} lg={6} className={classes.todaybottom}>Today</td>
                                            <td xs={2} sm={2} md={2} lg={2}>{activedevotee_count3}</td>
                                            <td xs={2} sm={2} md={2} lg={2}>{activedevotee_count4}</td>
                                          </tr>
                                          {activedevotee_statistics.map((person, index) => (
                                              <div key={index} className={classes.statsdata}>
                                                <tr >
                                                <td xs={6} sm={6} md={6} lg={6} className={classes.countrydata}>{person.country}</td>
                                                <td xs={2} sm={2} md={2} lg={2}>{person.countrywisesessioncount}</td>
                                                <td xs={2} sm={2} md={2} lg={2}>{person.countrywisemantracount}</td>
                                              </tr>
                                              <tr >
                                                <td xs={6} sm={6} md={6} lg={6}></td>
                                                <td xs={2} sm={2} md={2} lg={2}></td>
                                                <td xs={2} sm={2} md={2} lg={2}></td>
                                              </tr>
                                              {person.statedata.map((state, indexs) => (
                                                <tr key={indexs}>
                                                  <td xs={6} sm={6} md={6} lg={6} className={classes.statename} >{state.state}</td>
                                                  <td xs={2} sm={2} md={2} lg={2}>{state.statewisesessioncount}</td>
                                                  <td xs={2} sm={2} md={2} lg={2}>{state.statewisemantracount}</td>
                                                </tr>
                                                ))}
                                                </div> 
                                          ))}
                                        </table>
                                        </GridContainer>
                                  </CardBody>               
                                </Card>
                              </GridItem>
                            </GridContainer>
                        </Grid>
                    </Container>
                </div>               
                <Footer/>
            </div>        
       </div>
    );
  }
}

ActiveDevoteeStatistics.propTypes = {
  classes: PropTypes.object
};



 const mapStateToProps = state => {
  // console.log('in maptoprops:',state);
  
  return {
    user: state.auth.user,    
    activedevotee_statistics : state.activedevoteeReducer.activedevotee_statistics,    
    activedevotee_count1 : state.activedevoteeReducer.activedevotee_count1,
    activedevotee_count2 : state.activedevoteeReducer.activedevotee_count2,
    activedevotee_count3 : state.activedevoteeReducer.activedevotee_count3,
    activedevotee_count4 : state.activedevoteeReducer.activedevotee_count4,
    shownotification: state.activedevoteeReducer.shownotification,
    activedevotee_error : state.activedevoteeReducer.activedevotee_error,    
    notification_message:state.activedevoteeReducer.notification_message
  };
};
const mapDispatchToProps = { logoutUser ,resetActiveDevoteeNotification,listActiveDevoteeStatistics };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ActiveDevoteeStatistics));