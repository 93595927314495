import {
  primaryColor,
  secondaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  blackColor
} from "assets/jss/material-kit-pro-react.js";

const accordionStyle = theme => ({
  root: {
    flexGrow: 1,
    marginBottom: "45px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "40px",
    },
  },
  expansionPanel: {
    boxShadow: "none",
    "&:before": {
      display: "none !important"
    }
  },
  expansionPanelExpanded: {
    margin: "0 0 15px !important"
  },
  expansionPanelSummary: {
    minHeight: "auto !important",
    backgroundColor: "transparent",
    borderBottom: "1px solid " + grayColor[6],
    padding: "10px 10px 5px 0px",
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    color: grayColor[1],
    [theme.breakpoints.down("md")]: {
      padding: "15px 10px 0px 0px",
    },
  },
  primaryExpansionPanelSummary: {
    "&:hover": {
      color: primaryColor[0]
    }
  },
  secondaryExpansionPanelSummary: {
    "&:hover": {
      color: secondaryColor[0]
    }
  },
  warningExpansionPanelSummary: {
    "&:hover": {
      color: warningColor[0]
    }
  },
  dangerExpansionPanelSummary: {
    "&:hover": {
      color: dangerColor[0]
    }
  },
  successExpansionPanelSummary: {
    "&:hover": {
      color: successColor[0]
    }
  },
  infoExpansionPanelSummary: {
    "&:hover": {
      color: infoColor[0]
    }
  },
  roseExpansionPanelSummary: {
    "&:hover": {
      color: roseColor[0]
    }
  },
  expansionPanelSummaryExpaned: {
    "& $expansionPanelSummaryExpandIcon": {
      [theme.breakpoints.up("md")]: {
        top: "auto !important"
      },
      transform: "rotate(180deg)",
      [theme.breakpoints.down("sm")]: {
        top: "10px !important"
      },
      // some jss/css to make the cards look a bit better on Internet Explorer
      "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
        display: "inline-block !important",
        top: "10px !important"
      }
    }
  },
  primaryExpansionPanelSummaryExpaned: {
    color: primaryColor[0]
  },
  secondaryExpansionPanelSummaryExpaned: {
    color: secondaryColor[0]
  },
  warningExpansionPanelSummaryExpaned: {
    color: warningColor[0]
  },
  dangerExpansionPanelSummaryExpaned: {
    color: dangerColor[0]
  },
  successExpansionPanelSummaryExpaned: {
    color: successColor[0]
  },
  infoExpansionPanelSummaryExpaned: {
    color: infoColor[0]
  },
  roseExpansionPanelSummaryExpaned: {
    color: roseColor[0]
  },
  expansionPanelSummaryContent: {
    margin: "0 !important"
  },
  expansionPanelSummaryExpandIcon: {
    [theme.breakpoints.up("md")]: {
      top: "auto !important"
    },
    transform: "rotate(0deg)",
    color: "inherit",
    right: "10px",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      top: "10px !important"
    },
    // some jss/css to make the cards look a bit better on Internet Explorer
    "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      display: "inline-block !important"
    }
  },
  expansionPanelSummaryExpandIconExpanded: {},
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    marginTop: "0",
    color: blackColor,
    [theme.breakpoints.down("md")]: {
      margin: "0 auto 10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
    },
  },
  expansionPanelDetails: {
    display: "block",
    padding: "25px 0px",
    fontSize: ".875rem"
  },
  paddingBoth: {
    paddingLeft: '15px',
    paddingRight: '15px'
  },
  textRight: {
    textAlign: 'right'
  },
  textCenter: {
    textAlign: 'center'
  },
  mainContent: {
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: '500',
    paddingTop: '10px',
    color: successColor[0],
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },
  subContent: {
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: '400',
    paddingTop: '10px',
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  meanningContent: {
    textAlign: 'center',
    fontSize: '18px',
    paddingTop: '10px',
  },
  langBtn: {
    margin: "15px 15px 15px auto",
    maxWidth: "150px",
    float: "right"
  },
  playBlock: {
    border: "1px solid " + successColor[0],
    display: "inline-block",
    marginLeft: "15px",
    marginBottom: "15px",
    padding: "5px 5px 5px 15px"
  },
  switchcolor: {
    color: "#4caf50 !important"
  }
});

export default accordionStyle;
